import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { useAuthStore } from "../stores/authStore";

const ongoingRequests = new Map();

// const generateRequestId = (config: InternalAxiosRequestConfig) => {
//     const { method, url, params } = config;
//     const paramString = params ? JSON.stringify(params) : "";
//     return `${method}-${url}-${paramString}`;
// };
//
// axios.interceptors.request.use((config) => {
//     const requestId = generateRequestId(config);
//
//     if (ongoingRequests.has(requestId)) {
//         return Promise.reject({ isCanceled: true });
//     }
//
//     ongoingRequests.set(requestId, true);
//
//     return config;
// });
//
// axios.interceptors.response.use(
//     (response) => {
//         const requestId = generateRequestId(response.config);
//         ongoingRequests.delete(requestId);
//
//         return response;
//     },
//     (error) => {
//         const requestId = error.config
//             ? generateRequestId(error.config)
//             : "unknown-request";
//         ongoingRequests.delete(requestId);
//
//         return Promise.reject(error);
//     }
// );

class Http {
    private baseUrl: string;
    private authToken: string | null = null;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public setAuthToken(authToken: string | null) {
        this.authToken = authToken;
    }

    async get<T>(
        url: string,
        params?: any,
        authToken?: string,
        isBlob: boolean = false
    ): Promise<T> {
        if (isBlob) {
            const options: AxiosRequestConfig = {
                method: "GET",
                url,
                params,
                responseType: "blob",
            };
            return this.request<T>(options, authToken || this.authToken);
        }
        const options: AxiosRequestConfig = { method: "GET", url, params };
        return this.request<T>(options, authToken || this.authToken);
    }

    async post<T>(
        url: string,
        data?: any,
        authToken?: string,
        contentType: string = "application/json"
    ): Promise<T> {
        let headers: any = {
            Accept: "application/json",
            Authorization: authToken ? `Bearer ${authToken}` : "",
        };

        if (contentType === "multipart/form-data") {
            headers["Content-Type"] = "multipart/form-data";
        } else {
            headers["Content-Type"] = "application/json";
        }

        const options: AxiosRequestConfig = {
            method: "POST",
            url,
            data,
            headers,
        };

        return this.request<T>(options, authToken || this.authToken);
    }

    async put<T>(url: string, data?: any, authToken?: string): Promise<T> {
        const options: AxiosRequestConfig = { method: "PUT", url, data };
        return this.request<T>(options, authToken || this.authToken);
    }

    async delete<T>(url: string, data?: any, authToken?: string): Promise<T> {
        const options: AxiosRequestConfig = { method: "DELETE", url, data };
        return this.request<T>(options, authToken || this.authToken);
    }

    private async request<T>(
        options: AxiosRequestConfig,
        authToken: string | null
    ): Promise<T> {
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: authToken ? `Bearer ${authToken}` : "",
            ...options.headers,
        };
        const mergedOptions = {
            ...options,
            headers,
            url: this.baseUrl + options.url,
        };
        try {
            const response = await axios.request<T>(mergedOptions);
            return response.data;
        } catch (error) {
            // @ts-ignore
            if (error.response?.status === 419) {
                //force logout
                alert("Your session has expired. Please login again.");
                const AuthStore = useAuthStore();
                AuthStore.logout();
                AuthStore.$reset();
                setTimeout(() => {
                    window.location.href =
                        import.meta.env.VITE_APP_URL + "/user-login";
                }, 1000);
            }
            throw error;
        }
    }
}

export default Http;
