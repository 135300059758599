import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import Http from "../services/Http";
import * as Sentry from "@sentry/browser";
import _ from "lodash";
import EmployeeInterface, { EmployeeStatusHistoryInterface, EmployeeStatusTypeGroupInterface, } from "../pages/interfaces/employeesInterface";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import ApiResponseBlobInterface from "../pages/interfaces/apiResponseBlobInterface";
import { OfficeInterface } from "../pages/interfaces/officeInterface";

export const useEmployeesStore = defineStore("EmployeesStore", {
    state: (): {
        mode: string;
        $sentry: null;
        userEmployee: EmployeeInterface | null;
        teamEmployees: any[];
        memberEmployees: any[];
        formData: any[];
        allowSensitiveData: boolean;
        employees: EmployeeInterface[];
        employee: EmployeeInterface;
        employeesTableData: ApiResponseInterface<any>;
        clientEmployees: any[];
        myTeam: any[];
    } => {
        return {
            mode: "Add",
            employees: [] as EmployeeInterface[],
            employee: {} as EmployeeInterface,
            employeesTableData: { status: "success", data: [], total: 0 },
            formData: [],
            clientEmployees: [],
            memberEmployees: [],
            teamEmployees: [],
            userEmployee: null,
            $sentry: null,
            allowSensitiveData: false,
            myTeam: [],
        };
    },
    getters: {
        getMode(): string {
            return this.mode;
        },
    },
    actions: {
        getDefaultEmployee(): EmployeeInterface {
            return {
                employee_teams: [],
                bank_account_no: "",
                bank_type: "",
                bank_name: "",
                id: 0,
                user_id: "",
                address_line_1: "",
                primary_work_location: "",
                regularization_date: "",
                status: "",
                employee_status_type_group: {} as EmployeeStatusTypeGroupInterface,
                address_line_2: null,
                city: null,
                orientation_date: "",
                start_date: "",
                zip: null,
                client_id: 0,
                client: null,
                third_party_employee_id: "",
                name: "",
                timezone_id: 1,
                position: "",
                personal_phone: "",
                personal_email: "",
                work_email: "",
                work_phone: "",
                client_email: "",
                office_id: "",
                office: {} as OfficeInterface,
                secondary_work_location: "",
                shift_schedule: null,
                notes: null,
                emergency_contact_name: "",
                emergency_contact_phone: "",
                emergency_contact_relationship: "",
                internal_staff_flag: "",
                sss: "",
                pagibig: "",
                tin: "",
                phic: "",
                payment_method: "",
                basic_salary: "",
                sensitiveData: false,
                attachments: [],
            };
        },
        async getAllEmployeesForTable(params: any): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/get-all-employees-for-table",
                    {
                        params,
                    }
                );
                this.employeesTableData = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getAllEmployees(params: any | null = null) {
            // if (this.employees.length > 0) {
            //     return;
            // }

            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<EmployeeInterface[]>>(
                    "/get-all-employees",
                    {
                        params,
                    }
                );
                this.employees = response.data;
                this.allowSensitiveData = !!response.sensitiveData;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        getEmployee(id: number) {
            const employee = _.find(this.employees, (employee) => {
                return employee.id === id;
            });
            this.mode = employee ? "Update" : "Add";
            return employee || this.getDefaultEmployee();
        },
        async getEmployeeDetails(id: number) {
            try {
                const { data } = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<EmployeeInterface>>(
                    `/employees/${id}`
                );

                this.employee = data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },
        async getClientEmployees(params: any) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<EmployeeInterface[]>>(
                    "/get-client-employees",
                    {
                        params,
                    }
                );
                this.clientEmployees = response.data;
                this.memberEmployees = response.data;
                this.teamEmployees = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getUserEmployeeData(id: number) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<EmployeeInterface>>(
                    "/get-user-employee",
                    {
                        params: { id },
                    }
                );
                this.userEmployee = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getEmployeeTeamLeaders(id: number) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<any>("/employee-team-leaders", {
                    params: { id },
                });
                this.myTeam = response.myTeam;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        filterByClient(id: number) {
            if (id !== 0 && id !== null) {
                const filteredEmployees = _.filter(
                    this.employees,
                    (employee) => {
                        if (employee.client_id === id) {
                            return employee;
                        }
                    }
                );
                return filteredEmployees;
            } else {
                return this.employees;
            }
        },

        async save(formData: any) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>("/employees", formData);
                return { status: response.status, data: response.data };
            } catch (error: any) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error?.response?.data?.errors, "save");
                return { status: "error", data: error?.response?.data?.errors };
            }
        },
        async update(formData: any) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).put<ApiResponseInterface<EmployeeInterface>>(
                    "/employees",
                    formData
                );
                return { status: response.status, data: response.data };
            } catch (error: any) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error.response.data.errors);
                return { status: "error", data: error?.response?.data?.errors };
            }
        },
        async delete(id: number) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).delete<ApiResponseInterface<EmployeeInterface>>(
                    "/employees",
                    {
                        data: { id },
                    }
                );
                return response;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async uploadAttachment(formData: any) {
            try {
                const AuthStore = useAuthStore();
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>(
                    "/employee-attachments",
                    formData,
                    AuthStore.token,
                    "multipart/form-data"
                );
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },
        async downloadAttachment(params: any) {
            let stats = "";
            let files = "";
            try {
                const AuthStore = useAuthStore();
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseBlobInterface>(
                    "/employee-attachments",
                    { params },
                    AuthStore.token,
                    true
                );
                const fileURL = window.URL.createObjectURL(
                    // @ts-ignore
                    new Blob([response])
                );
                const fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute("download", params.file_name);
                document.body.appendChild(fileLink);

                fileLink.click();
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
                return { status: "error" };
            }
        },
        async deleteAttachment(id: number) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).delete<ApiResponseInterface<any>>(
                    `/employee-attachments/${id}`
                );
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },
        async deactivateEmployee(payload: any) {
            try {
                await new Http(import.meta.env.VITE_API_URL).post<void>(
                    "/employee-deactivation",
                    payload
                );
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },
        async importEmployees(payload: FormData) {
            try {
                const AuthStore = useAuthStore();
                const response = await new Http(import.meta.env.VITE_API_URL).post<any>(
                    "/import-employees",
                    payload,
                    AuthStore.token,
                    "multipart/form-data"
                );

                return {
                    status: response.status
                };
            } catch (error: any) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error)
                return {
                    status: "error",
                    message: error,
                    error: error?.response?.data
                };
            }
        },
        async downloadImportTemplate() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/employee-import-template",
                    {},
                    "",
                    true
                );
                // @ts-ignore
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute(
                    "download",
                    "employee_import_template.csv"
                );
                document.body.appendChild(fileLink);

                fileLink.click();
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
                return { status: "error" };
            }
        },
        async importEmployeeInvoiceInformationImportTemplate(
            payload: FormData
        ) {
            try {
                const AuthStore = useAuthStore();
                await new Http(import.meta.env.VITE_API_URL).post<void>(
                    "/import-employee-invoice-information",
                    payload,
                    AuthStore.token,
                    "multipart/form-data"
                );
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },
        async downloadEmployeeInvoiceInformationImportTemplate() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/employee-invoice-information-template",
                    {},
                    "",
                    true
                );
                // @ts-ignore
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute(
                    "download",
                    "employee_invoice_information_template.csv"
                );
                document.body.appendChild(fileLink);

                fileLink.click();
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
                return { status: "error" };
            }
        },
        async importEmployeeShift(payload: FormData) {
            try {
                const AuthStore = useAuthStore();
                await new Http(import.meta.env.VITE_API_URL).post<void>(
                    "/import-employee-shifts",
                    payload,
                    AuthStore.token,
                    "multipart/form-data"
                );
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },
        async downloadEmployeeShiftImportTemplate() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/employee-shifts-import-template",
                    {},
                    "",
                    true
                );
                // @ts-ignore
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute(
                    "download",
                    "employee-shifts-import-template.csv"
                );
                document.body.appendChild(fileLink);

                fileLink.click();
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
                return { status: "error" };
            }
        },

        addState() {
            this.mode = "Add";
        },
        updateState() {
            this.mode = "Update";
        },
        setMemberEmployees(data: any[]) {
            this.memberEmployees = data;
        },
        setClientEmployees(data: any[]) {
            this.clientEmployees = data;
        },
    },
});
