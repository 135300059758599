import EmployeesView from "@/pages/EmployeesView.vue";
import PermissionInterface from "@/pages/interfaces/permissionInterface";
import { useUiStore } from "@/stores/uiStore";

import {
    createRouter,
    createWebHistory,
    RouteRecordName,
    RouteRecordRaw,
} from "vue-router";

const hrDasbhoard = [
    "admin",
    "payroll",
    "accounts receivable",
    "hr",
    "recruitment",
    "it",
];

const clientRoles = [
    "client",
    "client-user",
    "client-sales",
    "client-recruitment",
];

let rePortalRoutes: Array<RouteRecordRaw> = [
    {
        path: "clients",
        name: "clients",
        component: () => import("@/pages/ClientsView.vue"),
        meta: {
            requiredPermission: "can view all clients",
        },
    },
    {
        path: "clients/:id",
        name: "clientsDetails",
        component: () =>
            import("@/pages/ClientDetailsView.vue"),
        meta: {
            requiredPermission: "can read clients",
        },
    },
    {
        path: "client-notifications",
        name: "clientNotifications",
        component: () =>
            import("@/pages/ClientNotifications.vue"),
        meta: {
            requiredPermission: [
                "can all view client notifications",
                "can view all clients",
            ],
        },
    },
    {
        path: "client-notifications-add",
        name: "clientNotificationsAdd",
        component: () =>
            import(
                "@/pages/forms/clientNotifications/FormClientNotifications.vue"
            ),
        meta: {
            requiredPermission:
                "can create client notifications",
        },
    },
    {
        path: "client-notifications-preview",
        name: "clientNotificationsPreview",
        component: () =>
            import(
                "@/pages/forms/clientNotifications/FormClientNotificationsPreview.vue"
            ),
        meta: {
            requiredPermission:
                "can all view client notifications",
        },
    },
    {
        path: "employees",
        name: "employees",
        component: EmployeesView,
        meta: {
            requiredPermission: [
                "can view all employees",
                "can read employees",
            ],
        },
    },
    {
        path: "employees/attendance",
        name: "employeesAttendance",
        component: () =>
            import("@/pages/EmployeesAttendanceView.vue"),
        meta: {
            requiredPermission: [
                "can view attendance",
                "can view all employees",
            ],
        },
    },
    {
        path: "employees/schedule-today",
        name: "employeesScheduleToday",
        component: () =>
            import("@/pages/SchedulesToday.vue"),
        meta: {
            requiredPermission:
                "can view employee schedule today",
        },
    },
    {
        path: "employees/shifts",
        name: "employeesShifts",
        component: () =>
            import("@/pages/EmployeeShiftsView.vue"),
        meta: {
            requiredPermission: [
                "can view employee shifts",
                "can view all employees",
            ],
        },
    },
    {
        path: "employees/:id",
        name: "employeesDetails",
        component: () =>
            import("@/pages/EmployeeDetailsView.vue"),
        meta: {
            requiredPermission: [
                "can view all employees",
                "can read employees",
            ],
        },
    },
    {
        path: "my-team",
        name: "myTeam",
        component: () => import("@/pages/MyTeam.vue"),
    },
    {
        path: "/teams",
        name: "teams",
        component: () => import("@/pages/TeamView.vue"),
        meta: {
            requiredPermission: "can view all teams",
        },
    },
    {
        path: "/all-tickets",
        name: "tickets",
        component: () => import("@/pages/Tickets.vue"),
        meta: {
            requiredPermission: "can view all tickets",
        },
    },
    {
        path: "/ticket-details/:id",
        name: "ticketDetails",
        component: () =>
            import("@/pages/TicketDetails.vue"),
        meta: {
            requiredPermission: [
                "can view all tickets",
                "can view assigned tickets",
                "can view ticket for cctv recordings",
                "can view ticket for log ins activation",
                "can view ticket for log ins deactivation",
                "can view new candidate search ticket",
            ],
        },
    },
    {
        path: "/submit-a-ticket",
        name: "submitATicket",
        component: () => import("@/pages/TicketList.vue"),
        meta: {
            requiredPermission: "can create ticket",
        },
    },
    {
        path: "/ticket",
        name: "ticket",
        component: () =>
            import("@/pages/SubmitATicket.vue"),
        meta: {
            requiredPermission: "can create ticket",
        },
    },
    {
        path: "/my-tickets",
        name: "myTickets",
        component: () => import("@/pages/MyTickets.vue"),
        meta: {
            requiredPermission: "can create ticket",
        },
    },
    {
        path: "/tickets-for-approval",
        name: "ticketsForApproval",
        component: () =>
            import("@/pages/TicketsForApproval.vue"),
        meta: {
            requiredPermission: [
                "can approve all tickets",
                "can approve assigned tickets",
            ],
        },
    },
    {
        path: "/shifts",
        name: "shifts",
        component: () => import("@/pages/ShiftsView.vue"),
        meta: {
            requiredPermission: "can view employee shifts",
        },
    },
    {
        path: "/shifts/:id",
        name: "shiftsDetails",
        component: () =>
            import("@/pages/ShiftDetailsEmployee.vue"),
        meta: {
            requiredPermission:
                "can view employee shift details",
        },
    },
    {
        path: "/activity-logs",
        name: "activityLogs",
        component: () => import("@/pages/ActivityLogs.vue"),
        meta: {
            requiredRole: ["admin", "hr"],
        },
    },
    {
        path: "/activity-logs/shifts",
        name: "shiftActivityLogs",
        component: () =>
            import("@/pages/ShiftActivityLogs.vue"),
        meta: {
            requiredRole: ["admin", "hr"],
        },
    },
    {
        path: "/activity-logs/positions",
        name: "positionActivityLogs",
        component: () =>
            import("@/pages/PositionActivityLogs.vue"),
        meta: {
            requiredRole: ["admin", "hr"],
        },
    },
    {
        path: "/payroll-configurations",
        name: "payrollConfigurations",
        component: () =>
            import(
                "@/pages/payroll/PayrollConfigurationView.vue"
            ),
        meta: {
            requiredPermission:
                "can view payroll configurations",
        },
    },
    {
        path: "/my-timesheets",
        name: "myTimesheets",
        component: () =>
            import(
                "@/pages/my-timesheet/MyTimesheetView.vue"
            ),
        // meta: {
        //     requiredPermission: "can view my timesheets",
        // },
    },
    {
        path: "/account-management-view",
        name: "accountManagementView",
        component: () =>
            import("@/pages/AccountManagementView.vue"),
        meta: {
            requiredRole: "admin",
        },
    },
    {
        path: "/account-management/:id",
        name: "account-management",
        component: () =>
            import("@/pages/AccountManagement.vue"),
        meta: {
            requiredRole: "admin",
        },
    },
    {
        path: "/roles-and-permissions",
        name: "rolesAndPermissions",
        component: () =>
            import("@/pages/RolesAndPermissions.vue"),
        meta: {
            requiredRole: "admin",
        },
    },
    {
        path: "invoices-dashboard",
        name: "invoicesDashboard",
        component: () =>
            import("@/pages/DashboardInvoices.vue"),
        meta: {
            requiredPermission: "can view invoices",
        },
    },
    {
        path: "/invoices",
        name: "invoices",
        component: () => import("@/pages/InvoiceView.vue"),
        meta: {
            requiredPermission: "can view invoices",
        },
    },
    {
        path: "/invoices/:id",
        name: "invoice-details",
        component: () =>
            import("@/pages/InvoiceDetailsView.vue"),
        meta: {
            requiredPermission: "can view invoices",
        },
    },
    {
        path: "/candidate/:id",
        name: "candidateDetails",
        component: () =>
            import("@/pages/CandidateDetails.vue"),
        meta: {
            requiredRole: [
                "admin",
                "client",
                "hr",
                "recruitment",
                "sales"
            ],
        },
    },
    {
        path: "/recruitment",
        name: "clientOpenPositions",
        component: () =>
            import("@/pages/ClientOpenPositions.vue"),
        meta: {
            requiredRole: [
                "admin",
                "client",
                "hr",
                "recruitment",
                "sales",
            ],
        },
    },
    {
        path: "/recruitment/job-description-templates",
        name: "jobDescriptionTemplates",
        component: () =>
            import("@/pages/JobDescriptionTemplates.vue"),
        meta: {
            requiredRole: [
                "admin",
                "client",
                "hr",
                "recruitment",
                "sales",
            ],
        },
    },
    {
        path: "/recruitment/job-description-templates/view/:id",
        name: "viewJobDescriptionTemplate",
        component: () =>
            import(
                "@/pages/JobDescriptionTemplateView.vue"
            ),
        meta: {
            requiredRole: [
                "admin",
                "client",
                "hr",
                "recruitment",
                "sales",
            ],
        },
    },
    {
        path: "/recruitment/:id",
        name: "positionDetails",
        component: () =>
            import("@/pages/PositionDetails.vue"),
        meta: {
            requiredRole: [
                "admin",
                "client",
                "hr",
                "recruitment",
                "sales",
            ],
        },
    },
    {
        path: "/recruitment/customer-notes",
        name: "customerNotes",
        component: () =>
            import("@/pages/CustomerNotes.vue"),
        meta: {
            requiredRole: [
                "admin",
                "client",
                "hr",
                "recruitment",
                "sales",
            ],
        },
    },
    {
        path: "/recruitment/position-candidates/:uuid",
        name: "positionCandidatesMobile",
        component: () =>
            import("@/pages/PositionCandidatesMobile.vue"),
        meta: {
            requiredRole: [
                "admin",
                "client",
                "hr",
                "recruitment",
                "sales",
            ],
        },
    },
    {
        path: "/recruitment/position-notes/:uuid",
        name: "positionNotesMobile",
        component: () =>
            import("@/pages/PositionNotesMobile.vue"),
        meta: {
            requiredRole: [
                "admin",
                "client",
                "hr",
                "recruitment",
                "sales",
            ],
        },
    },
    {
        path: "/recruitment/position-attachments/:uuid",
        name: "positionAttachmentsMobile",
        component: () =>
            import("@/pages/PositionAttachmentMobile.vue"),
        meta: {
            requiredRole: [
                "admin",
                "client",
                "hr",
                "recruitment",
                "sales",
            ],
        },
    },
    {
        path: "/recruitment/interview/:id",
        name: "positionInterviewDetails",
        component: () =>
            import("@/pages/PositionInterviewDetails.vue"),
        meta: {
            requiredRole: [
                "admin",
                "client",
                "hr",
                "recruitment",
                "sales",
            ],
        },
    },
];

const clientPortalRoutes: Array<RouteRecordRaw> = [
    {
        path: "/positions",
        name: "positions",
        component: () => import("@/pages/Positions.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/hire",
        name: "hire",
        component: () =>
            import("@/pages/PositionBuildTeam.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/build-path",
        name: "buildPath",
        component: () =>
            import("@/pages/PositionBuildPath.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/automate-job-description",
        name: "automateJobDescription",
        component: () =>
            import(
                "@/pages/PositionSearchJobDescription.vue"
            ),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/review-generated-description",
        name: "reviewGeneratedDescription",
        component: () =>
            import(
                "@/pages/PositionGeneratedInsights.vue"
            ),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/create",
        name: "craftJobDescription",
        component: () =>
            import(
                "@/pages/PositionCraftJobDescription.vue"
            ),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/finalize",
        name: "finalizeJobDescription",
        component: () =>
            import(
                "@/pages/PositionAdditionalInformation.vue"
            ),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/edit",
        name: "editJobDescription",
        component: () =>
            import(
                "@/pages/PositionCraftJobDescription.vue"
            ),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/update/:uuid",
        name: "updateJobDescription",
        component: () =>
            import(
                "@/pages/PositionCraftJobDescription.vue"
            ),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/review",
        name: "reviewSearchJobDescription",
        component: () =>
            import(
                "@/pages/PositionJobDescriptionReview.vue"
            ),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/view",
        name: "reviewYourJobDescription",
        component: () =>
            import(
                "@/pages/PositionJobDescriptionReview.vue"
            ),
        meta: {
            requiredRole: clientRoles,
        },
    },
    
    {
        path: "/position/my-open-positions",
        name: "myOpenPositions",
        component: () =>
            import("@/pages/PositionMyOpenPositions.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/my-open-positions/:uuid",
        name: "myOpenPosition",
        component: () =>
            import("@/pages/PositionMyOpenPosition.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/candidate-profile/:uuid",
        name: "candidateProfile",
        component: () =>
            import("@/pages/PositionCandidateProfile.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/hire/additional-details",
        name: "additionalPositionDetails",
        component: () =>
            import(
                "@/pages/PositionAdditionalInformation.vue"
            ),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/hire/generated-insights",
        name: "generatedInsights",
        component: () =>
            import("@/pages/PositionGeneratedInsights.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/success-positon/:uuid",
        name: "successfullyOpenedPosition",
        component: () =>
            import("@/pages/PositionSuccessful.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/my-open-positions/candidates/:uuid",
        name: "mobileCandidates",
        component: () =>
            import("@/pages/PositionCandidatesMobile.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/my-open-positions/notes/:uuid",
        name: "mobileNotes",
        component: () =>
            import("@/pages/PositionNotesMobile.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/my-open-positions/attachment/:uuid",
        name: "mobileAttachment",
        component: () =>
            import("@/pages/PositionAttachmentMobile.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/available-schedule",
        name: "AvailableSchedule",
        component: () =>
            import("@/pages/PositionAvailableSchedule.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/position/my-available-schedule/create",
        name: "createSchedule",
        component: () =>
            import("@/pages/PositionScheduleCreate.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/interview/schedules",
        name: "clientUserSchedules",
        component: () =>
            import("@/pages/InterviewSchedules.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/interview/schedules/:uuid",
        name: "editClientUserSchedule",
        component: () =>
            import("@/pages/InterviewSchedules.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/my-notes",
        name: "myNotes",
        component: () =>
            import("@/pages/MyNotes.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
    {
        path: "/my-account-manager",
        name: "myAccountManager",
        component: () =>
            import("@/pages/MyAccountManager.vue"),
        meta: {
            requiredRole: clientRoles,
        },
    },
];

export const routes: Array<RouteRecordRaw> = [
    {
        path: "/user-login",
        name: "login",
        component: () => import("@/pages/Login.vue"),
    },
    {
        path: "/external-account-setup",
        name: "external-account-setup",
        component: () =>
            import("@/pages/ExternalAccountSetup.vue"),
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () =>
            import("@/pages/ForgotPassword.vue"),
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: () =>
            import("@/pages/ResetPassword.vue"),
    },
    {
        path: "/forbidden",
        name: "forbidden",
        component: () =>
            import("@/pages/components/403.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "page-not-found",
        component: () =>
            import("@/pages/components/404.vue"),
    },
];

// @ts-ignore
const roles = window.Laravel.role;
// @ts-ignore
if (window.Laravel.isLoggedin) {
    // const isHrDashboard = hrDasbhoard.includes(roles[0]);
    const isHrDashboard = hrDasbhoard.some(item => roles.includes(item));
    const isEmployeeRole = roles[0] === "employee";
    // const isClientRole = clientRoles.includes(roles[0]);
    const isClientRole = clientRoles.some(item => roles.includes(item));

    let childRoutes: Array<RouteRecordRaw> = [
        {
            path: "/change-password",
            name: "change-password",
            component: () =>
                import("@/pages/ChangePassword.vue"),
        },
    ];

    if (isClientRole && roles.length === 1) {
        // Client Dashboard
        clientPortalRoutes.push({
            path: "/dashboard",
            name: "dashboard",
            component: () =>
                import("@/pages/DashboardClient.vue"),
            meta: {
                requiredRole: clientRoles,
            },
        });
        routes.push({
            path: "/",
            name: "position",
            component: () => import("@/pages/Position.vue"),
            meta: {
                requiredRole: clientRoles,
            },
        });

        childRoutes = childRoutes.concat(clientPortalRoutes);

        routes.push({
            path: "/",
            component: () =>
                import("@/pages/layout/SideMenu.vue"),
            children: childRoutes,
        });
    } else {
        if (isEmployeeRole && roles.length === 1) {
            // Remove multiple route names from rePortalRoutes
            rePortalRoutes = rePortalRoutes.filter(
                (route) =>
                    route.name !== "clients" &&
                    route.name !== "clientsDetails" &&
                    route.name !== "clientNotifications" &&
                    route.name !==
                        "clientNotificationsAdd" &&
                    route.name !==
                        "clientNotificationsPreview" &&
                    route.name !== "employees" &&
                    route.name !== "employeesAttendance" &&
                    route.name !==
                        "employeesScheduleToday" &&
                    route.name !== "employeesShifts" &&
                    route.name !== "employeesDetails" &&
                    route.name !== "teams" &&
                    route.name !== "shifts" &&
                    route.name !== "invoice-details" &&
                    route.name !== "invoices" &&
                    route.name !== "invoicesDashboard" &&
                    route.name !== "rolesAndPermissions" &&
                    route.name !== "account-management" &&
                    route.name !==
                        "accountManagementView" &&
                    route.name !==
                        "payrollConfigurations" &&
                    route.name !== "activityLogs" &&
                    route.name !== "tickets",
            );
        }

        if (isHrDashboard && isClientRole) {
            rePortalRoutes.push({
                path: "/",
                name: "dashboard",
                component: () =>
                    import("@/pages/DashboardHr.vue"),
            });
            routes.push({
                path: "/position",
                name: "position",
                component: () =>
                    import("@/pages/Position.vue"),
            });
        } else if (isHrDashboard) {
            rePortalRoutes.push({
                path: "/",
                name: "dashboard",
                component: () =>
                    import("@/pages/DashboardHr.vue"),
            });
        } else {
            // Employee Dashboard
            rePortalRoutes.push({
                path: "/",
                name: "dashboard",
                component: () =>
                    import("@/pages/Dashboard.vue"),
            });
        }

        childRoutes = childRoutes.concat(rePortalRoutes);
        if (isClientRole) {
            childRoutes = childRoutes.concat(clientPortalRoutes);
        }

        routes.push({
            path: "/",
            component: () =>
                import("@/pages/layout/SideMenu.vue"),
            children: childRoutes,
        });
    }
}

if (
    !routes.find((route) => route.name === "dashboard") &&
    !(window as any).Laravel.isLoggedin
) {
    routes.push({
        path: "/",
        name: "dashboard",
        component: () => import("@/pages/Dashboard.vue"),
    });
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { left: 0, top: 0 };
    },
});

router.beforeEach((to) => {
    const publicRoutes = <RouteRecordName[]>[
        "login",
        "external-account-setup",
        "forgot-password",
        "reset-password",
        "forbidden",
        "page-not-found",
    ];

    const UiStore = useUiStore();
    if (to.name === "clientUserSchedules") {
        UiStore.setBreadcrumb('Available Schedule');
    } else if (to.name === "editClientUserSchedule") {
        UiStore.setBreadcrumb('Edit Schedule');
    } else {
        //@ts-ignore
        UiStore.setBreadcrumb(to.name);
    }
    const isAuthenticated = (window as any).Laravel?.isLoggedin;
    const requiredRole = to.meta.requiredRole;
    const requiredPermission = to.meta.requiredPermission;

    if (isAuthenticated) {
        if (
            to.name == "login" ||
            to.name == "forgot-password" ||
            to.name == "reset-password"
        ) {
            return "/";
        } else if (requiredRole) {
            const allowedRoles = Array.isArray(requiredRole)
                ? requiredRole
                : [requiredRole];
            if (
                (window as any).Laravel.role.filter(
                    (role: string) =>
                        allowedRoles.includes(role),
                ).length === 0
            ) {
                return "/forbidden";
            }
        } else if (requiredPermission) {
            const allowedPermissions = Array.isArray(
                requiredPermission,
            )
                ? requiredPermission
                : [requiredPermission];
            if (
                (window as any).Laravel.permissions.filter(
                    (permission: PermissionInterface) =>
                        allowedPermissions.includes(
                            permission.name,
                        ),
                ).length === 0
            ) {
                return "/forbidden";
            }
        } else if (!to.matched.length) {
            return "/page-not-found";
        }
    } else if (!isAuthenticated) {
        if (!publicRoutes.includes(to.name || "")) {
            sessionStorage.setItem(
                "redirectUrl",
                to.fullPath,
            );
            return "/user-login";
        }
    }
});

export default router;
