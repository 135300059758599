<script lang="ts" setup>
    import { onMounted, reactive, ref } from "vue";
    import "primevue/resources/themes/saga-blue/theme.css";
    import "primevue/resources/primevue.min.css";
    import "primeicons/primeicons.css";
    import {
        FilterMatchMode,
        FilterOperator
    } from "primevue/api";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    // import InputSwitch from "primevue/inputswitch";
    import InputText from "primevue/inputtext";
    import { useAuthStore } from "@/stores/authStore";
    import { useEmployeesStore } from "@/stores/employeesStore";
    import { useHelperStore } from "@/stores/helperStore";
    import { useUiStore } from "@/stores/uiStore";
    // import EmployeeInterface from "@/pages/interfaces/employeesInterface";
    import LazyParamsInterface from "@/pages/interfaces/lazyParamsInterface";
    import Button from "@/base-components/Button";
    import { FormInput } from "@/base-components/Form";
    import Lucide from "@/base-components/Lucide";
    import ConfirmModal from "@/pages/components/ConfirmModal.vue";
    import FormEmployee from "@/pages/forms/FormEmployee.vue";
    import FormSlider from "@/pages/FormSlider.vue";
    import { FormSwitch } from "@/base-components/Form";
    // import { Menu } from "@/base-components/Headless";
    import EmployeeIncompleteConfigurationModal from "@/pages/components/employees/EmployeeIncompleteConfigurationModal.vue";
    import PageHeaderStandard from "@/pages/components/PageHeaderStandard.vue";
    import ImportEmployees from "@/pages/components/employees/ImportEmployees.vue";
    import ImportEmployeeShift from "@/pages/components/employees/ImportEmployeeShift.vue";
    import ImportInvoiceInformation from "@/pages/components/employees/ImportInvoiceInformation.vue";
    // import ImportPayrollItems from "@/pages/components/employees/ImportPayrollItems.vue";

    interface EmployeeData {
        id: number;
    }

    interface EmployeeApiData {
        data: any;
    }

    const AuthStore = useAuthStore();
    const EmployeeStore = useEmployeesStore();
    const HelperStore = useHelperStore();
    const UiStore = useUiStore();

    const initialLazyParams = {
        first: 0,
        rows: 30,
        sortField: null,
        sortOrder: null,
        filters: {
            global: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            position: {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            name: {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            personal_email: {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            primary_work_location: {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            "employeeStatusTypeGroup.name": {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            "shift.shift.name": {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            timezone_id: {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            },
            "client.legal_business_name": {
                operator: FilterOperator.AND,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }
                ]
            }
        }
    };

    let statesOptionsDetails = {};
    let timezoneOptionsDetails = {};
    const employeeApiData = reactive<EmployeeApiData>({
        data: []
    });

    const showInactives = ref(false);

    const showClearFilter = ref(false);
    const isEmployeeFormSliderOut = ref(false);
    const alertModelSettings = reactive({
        isDisplayed: false,
        mode: "",
        subject: "",
        message: ""
    });

    const allIncompleteConfiguration = ref(false);
    const incompleteConfiguration = ref<string[]>([]);
    const incompleteConfigurationOptions = ref([
        {
            label: "Employee Base Cost",
            value: "employeeCurrentCostHistory"
        },
        {
            label: "Employee Nonrefundable",
            value: "employeeNonrefundableHistory"
        },
        { label: "Employee RE Fee", value: "employeeReFee" },
        { label: "Employee Shift", value: "shift" },
        { label: "Employee Schedule", value: "schedules" }
    ]);

    const employees = ref();

    const selectedEmployees = ref();

    const selectAll = ref(false);

    const loading = ref(true);

    const totalRecords = ref(0);

    const lazyParams = ref<LazyParamsInterface>({
        rows: initialLazyParams.rows
    });

    const filters = ref(initialLazyParams.filters);

    const dt = ref();

    let timezone = ref();

    const columns = ref([
        { field: "name", header: "Employee Name" },
        { field: "position", header: "Position" },
        { field: "personal_email", header: "Email" },
        { field: "primary_work_location", header: "Location" }
    ]);

    const searchableCols = ref([
        "name",
        "position",
        "personal_email",
        "personal_phone",
        "work_email",
        "client_email",
        "primary_work_location",
        "secondary_work_location",
        "shift_schedule",
        "employeeStatusTypeGroup.name"
    ]);

    const formEmployeeSettings = reactive({
        id: 0
    });

    const employeeId = ref(0);
    const deleteId = ref(0);
    const showConfirmDialog = ref(false);

    onMounted(async () => {
        UiStore.setBreadcrumb("All Employees");

        filters.value = initialLazyParams.filters;
        lazyParams.value = {
            ...initialLazyParams,
            filters: filters.value
        };

        await loadLazyData();

        if (HelperStore.timezones.length === 0) {
            await HelperStore.fillTimezones();
        }
    });

    const closeSlider = async () => {
        employeeId.value = 0;
        isEmployeeFormSliderOut.value = false;

        await loadLazyData();
    };

    const createNew = () => {
        isEmployeeFormSliderOut.value = true;
        employeeId.value = 0;
    };

    const updateEmployee = async (data: EmployeeData) => {
        employeeId.value = data.id;
        employeeApiData.data = data;
        console.log(employeeApiData.data);
        isEmployeeFormSliderOut.value = true;
    };
    const confirmDelete = (data: { id: number }) => {
        deleteId.value = data.id;
        setConfirmDialog(true);
    };

    const setConfirmDialog = (value: boolean) => {
        showConfirmDialog.value = value;
    };

    const processDelete = (event: any) => {
        alertModelSettings.isDisplayed = false;
        if (event) {
            console.log(deleteId.value);
            EmployeeStore.delete(deleteId.value).then(
                (response: any) => {
                    if (response.status === "success") {
                        loadLazyData();
                        deleteId.value = 0;
                    }
                }
            );
        }
        setConfirmDialog(false);
    };

    const deleteEmployee = async (data: { id: any }) => {
        console.log("deleteEmployee", data);
        await EmployeeStore.delete(data.id);
        await loadLazyData();
    };

    const updateShowInactives = async () => {
        loading.value = true;
        showInactives.value = !showInactives.value;
        await loadLazyData();
        loading.value = false;
    };

    const handleFetchEmployees = () => {
        loadLazyData();
    };

    const loadLazyData = async () => {
        console.log("loadLazyData");
        loading.value = true;
        const params = {
            show_inactives: showInactives.value,
            dt_params: JSON.stringify(lazyParams.value),
            searchable_columns: JSON.stringify(
                searchableCols.value
            ),
            incomplete_configuration:
                incompleteConfiguration.value
        };

        await EmployeeStore.getAllEmployeesForTable(params);
        employees.value = EmployeeStore.employeesTableData.data;
        if (
            EmployeeStore.employeesTableData.total !== undefined
        ) {
            totalRecords.value =
                EmployeeStore.employeesTableData.total;
        }
        console.log("total records", totalRecords.value);
        loading.value = false;
    };

    const onPage = async (event: { filters?: any }) => {
        lazyParams.value = event;
        await loadLazyData();
    };

    const onSort = async (event: { filters?: any }) => {
        console.log("onSort");
        showClearFilter.value = true;
        lazyParams.value = event;
        await loadLazyData();
    };

    const onFilter = async () => {
        showClearFilter.value =
            filters.value.global.value !== "";
        lazyParams.value.filters = filters.value;
        await loadLazyData();
    };

    const onSelectAllChange = (event: { checked: any }) => {
        selectAll.value = event.checked;
    };

    const onRowSelect = () => {
        selectAll.value =
            selectedEmployees.value.length ===
            totalRecords.value;
    };

    const onRowUnselect = () => {
        selectAll.value = false;
    };

    const handleSelectIncompleteConfiguration = () => {
        allIncompleteConfiguration.value =
            incompleteConfiguration.value.length ===
            incompleteConfigurationOptions.value.length;
    };

    const handleSelectAllIncompleteConfiguration = () => {
        if (allIncompleteConfiguration.value) {
            incompleteConfiguration.value =
                incompleteConfigurationOptions.value.map(
                    (config) => config.value
                );
        } else {
            incompleteConfiguration.value = [];
        }
    };

    const onClearIncompleteConfiguration = () => {
        incompleteConfiguration.value = [];
        allIncompleteConfiguration.value = false;

        loadLazyData();
    };

    const formEmployeeStatus = (status: any) => {
        if (status) {
            loadLazyData();
        }
    };

    const resetGlobalFilter = () => {
        showClearFilter.value = false;

        filters.value = initialLazyParams.filters;
        lazyParams.value = {
            ...initialLazyParams,
            filters: filters.value
        };

        incompleteConfiguration.value = [];

        loadLazyData();
    };
</script>
<template>
    <div
        v-if="
            Object.values(
                AuthStore.activatedPermissionsName
            ).includes('can view all employees')
        "
        class="w-full"
    >
        <div class="w-full">
            <PageHeaderStandard :title="'Employees'" />
            <div
                class="intro-y col-span-12 mt-2 flex flex-wrap items-center
                    sm:flex-nowrap"
            >
                <Button
                    v-if="
                        Object.values(
                            AuthStore.activatedPermissionsName
                        ).includes('can create employees')
                    "
                    id="addNewEmployee"
                    class="hadow-md mr-2"
                    variant="customPrimary"
                    @click="createNew"
                >
                    Add New Employee
                </Button>

                <ImportEmployees @fetchEmployees="handleFetchEmployees" />
                <ImportEmployeeShift />
                <!--                <ImportPayrollItems />-->
                <ImportInvoiceInformation
                    v-if="
                        AuthStore.role.includes('admin') ||
                        AuthStore.role.includes(
                            'finance'
                        ) ||
                        AuthStore.role.includes('payroll')
                    "
                    @fetchEmployees="handleFetchEmployees"
                />
                <div class="align-center ml-2 flex flex-row items-center">
                    <FormSwitch>
                        <FormSwitch.Input
                            type="checkbox"
                            @change="updateShowInactives"
                        />
                    </FormSwitch>

                    <label class="ml-2">Show Inactive/Terminated</label>
                </div>

                <div class="mx-auto hidden text-slate-500 md:block"></div>
                <div
                    class="mt-3 flex w-full sm:ml-auto sm:mt-0 sm:w-auto md:ml-0"
                >
                    <template v-if="showClearFilter">
                        <button
                            class="btn mx-3 w-full rounded-md bg-custom-color-1000 px-4
                                text-white"
                            @click="resetGlobalFilter"
                        >
                            Clear
                            <span
                                class="pi pi-filter-slash p-component h-3 w-4"
                            ></span>
                        </button>
                    </template>
                    <div class="relative w-56 text-slate-500">
                        <FormInput
                            id="global-search"
                            v-model="
                                filters['global'].value
                            "
                            :value="filters['global'].value"
                            class="!box w-56 pr-10"
                            placeholder="Search..."
                            type="text"
                            @keyup.enter="onFilter"
                        />
                        <Lucide
                            class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4"
                            icon="Search"
                        />
                    </div>
                </div>
            </div>
            <DataTable
                ref="dt"
                v-model:filters="filters"
                v-model:selection="selectedEmployees"
                :globalFilterFields="searchableCols"
                :lazy="true"
                :loading="loading"
                :paginator="true"
                :rows="initialLazyParams.rows"
                :rowsPerPageOptions="[30, 50, 100]"
                :totalRecords="totalRecords"
                :value="employees"
                class="p-datatable-sm mt-4 rounded-lg text-xs shadow-lg"
                dataKey="id"
                filterDisplay="menu"
                responsiveLayout="scroll"
                showGridlines
                stripedRows
                @filter="onFilter()"
                @page="onPage($event)"
                @sort="onSort($event)"
                @select-all-change="onSelectAllChange"
                @row-select="onRowSelect"
                @row-unselect="onRowUnselect"
            >
                <template #empty>No Employees found.</template>
                <template #loading>
                    Loading Employee data. Please wait.
                </template>
                <Column
                    headerStyle="min-width: 36px"
                    selectionMode="multiple"
                ></Column>
                <Column
                    :show-add-button="false"
                    :show-filter-match-modes="false"
                    :show-filter-operator="false"
                    field="name"
                >
                    <template #body="{ data }">
                        <EmployeeIncompleteConfigurationModal
                            :employee="data"
                        />
                    </template>
                    <template #filter>
                        <h3 class="font-semibold">Incomplete Configuration</h3>

                        <div class="mt-4 flex items-center">
                            <input
                                id="config_all"
                                type="checkbox"
                                v-model="
                                    allIncompleteConfiguration
                                "
                                value="All"
                                @change="
                                    handleSelectAllIncompleteConfiguration
                                "
                            />
                            <label for="config_all" class="ml-2">All</label>
                        </div>

                        <div
                            v-for="(
                                config, index
                            ) in incompleteConfigurationOptions"
                            class="mt-2 flex items-center"
                        >
                            <input
                                :id="`config_${index}`"
                                type="checkbox"
                                v-model="
                                    incompleteConfiguration
                                "
                                :value="config.value"
                                @change="
                                    handleSelectIncompleteConfiguration
                                "
                            />
                            <label :for="`config_${index}`" class="ml-2">
                                {{ config.label }}
                            </label>
                        </div>
                    </template>
                    <template #filterclear>
                        <Button
                            class="border border-[#2196f3] px-2 py-1.5 text-[#2196f3]"
                            @click="
                                onClearIncompleteConfiguration
                            "
                        >
                            Clear
                        </Button>
                    </template>
                </Column>
                <Column :sortable="true" field="name" header="Name">
                    <template #body="{ data }">
                        <div class="flex items-center">
                            <!-- <Lucide
                                icon="AlertTriangle"
                                class="h-4 w-4 text-yellow-500"
                                v-if="
                                    data.employee_current_cost_history ===
                                        null ||
                                    data.employee_current_nonrefundable_history ===
                                        null ||
                                    data.employee_current_re_fee === null ||
                                    data.shift === null ||
                                    data.schedules_count === 0
                                "
                            /> -->

                            <span class="ml-2">
                                {{
                                data.name
                                }}
                            </span>
                        </div>
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Employee Name"
                            type="text"
                        />
                    </template>
                </Column>
                <Column :sortable="true" field="position" header="Position">
                    <template #body="{ data }">
                        {{ data.position }}
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Position"
                            type="text"
                        />
                    </template>
                </Column>
                <Column
                    :sortable="true"
                    field="client.legal_business_name"
                    header="Client Name"
                >
                    <template #body="{ data }">
                        {{
                            data.client.legal_business_name
                        }}
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Client"
                            type="text"
                        />
                    </template>
                </Column>
                <Column :sortable="true" field="timezone_id" header="Timezone">
                    <template #body="{ data }">
                        {{
                            HelperStore.getTimezone(
                                parseInt(data.timezone_id)
                            ).name
                        }}
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Timezone"
                            type="text"
                        />
                    </template>
                </Column>
                <Column
                    :sortable="true"
                    field="shift.shift.name"
                    header="Shift Schedule"
                >
                    <template #body="{ data }">
                        {{ data.shift?.shift?.name }}
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by shift"
                            type="text"
                        />
                    </template>
                </Column>
                <Column
                    :sortable="true"
                    field="personal_email"
                    header="Personal Email"
                >
                    <template #body="{ data }">
                        {{ data.personal_email }}
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Email"
                            type="text"
                        />
                    </template>
                </Column>
                <Column
                    :sortable="true"
                    field="primary_work_location"
                    header="Location"
                >
                    <template #body="{ data }">
                        {{ data.primary_work_location }}
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Work Location"
                            type="text"
                        />
                    </template>
                </Column>
                <Column
                    :sortable="true"
                    field="employeeStatusTypeGroup.name"
                    header="Status"
                >
                    <template #body="{ data }">
                        {{
                            data.employee_status_type_group
                                ?.name
                        }}
                    </template>
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by Status"
                            type="text"
                        />
                    </template>
                </Column>
                <Column
                    :exportable="false"
                    style="width: 8rem; max-width: 8rem"
                >
                    <template #body="slotProps">
                        <div class="flex content-center justify-center">
                            <!-- View -->
                            <div class="">
                                <router-link
                                    :to="{
                                        name: 'employeesDetails',
                                        params: {
                                            id: slotProps
                                                .data.id
                                        }
                                    }"
                                >
                                    <svg
                                        :id="
                                            'view_' +
                                            slotProps.data
                                                .id
                                        "
                                        class="icon icon-tabler icon-tabler-eye cursor-pointer"
                                        fill="none"
                                        height="18"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        viewBox="0 0 24 24"
                                        width="18"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                            stroke="none"
                                        ></path>
                                        <path
                                            d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"
                                        ></path>
                                        <path
                                            d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
                                        ></path>
                                    </svg>
                                </router-link>
                            </div>
                            <!-- View -->
                            <!-- Update Client Button -->
                            <div
                                v-if="
                                    Object.values(
                                        AuthStore.activatedPermissionsName
                                    ).includes(
                                        'can update employees'
                                    )
                                "
                                class="ml-2"
                            >
                                <svg
                                    :id="
                                        'update_' +
                                        slotProps.data.id
                                    "
                                    class="icon icon-tabler icon-tabler-edit cursor-pointer"
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                    @click="
                                        updateEmployee(
                                            slotProps.data
                                        )
                                    "
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    />
                                    <path
                                        d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                                    />
                                    <path
                                        d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                                    />
                                    <path d="M16 5l3 3" />
                                </svg>
                            </div>
                            <!-- Update -->
                            <!-- Delete -->
                            <div
                                v-if="
                                    Object.values(
                                        AuthStore.activatedPermissionsName
                                    ).includes(
                                        'can delete employees'
                                    )
                                "
                                class="ml-2"
                            >
                                <svg
                                    :id="
                                        'delete_' +
                                        slotProps.data.id
                                    "
                                    class="icon icon-tabler icon-tabler-trash cursor-pointer"
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                    @click="
                                        confirmDelete(
                                            slotProps.data
                                        )
                                    "
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    />
                                    <path d="M4 7l16 0" />
                                    <path d="M10 11l0 6" />
                                    <path d="M14 11l0 6" />
                                    <path
                                        d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                                    />
                                    <path
                                        d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                                    />
                                </svg>
                            </div>
                            <!-- Delete -->
                        </div>
                    </template>
                </Column>
                <template #paginatorstart>
                    <!--                <Button type="button" icon="pi pi-refresh" class="p-button-text"/>-->
                </template>
                <template #paginatorend>
                    <!--                <Button type="button" icon="pi pi-cloud" class="p-button-text"/>-->
                </template>
            </DataTable>
            <FormSlider
                :header="'Employee Form Details'"
                :isOpen="isEmployeeFormSliderOut"
                @close="closeSlider()"
            >
                <FormEmployee
                    :id="employeeId"
                    :data="employeeApiData.data"
                    @close="closeSlider()"
                    @status="formEmployeeStatus($event)"
                ></FormEmployee>
            </FormSlider>

            <ConfirmModal
                :displayConfirmModal="showConfirmDialog"
                second-description="Employee has been successfully deleted."
                @closeConfirmModal="setConfirmDialog(false)"
                @proceedProcess="processDelete($event)"
            />
        </div>
    </div>
</template>
