<script lang="ts" setup>
    import { defineProps, onMounted, ref, watch } from "vue";
    import { Dialog } from "@/base-components/Headless";
    import Button from "@/base-components/Button";
    import Lucide from "@/base-components/Lucide";

    const props = defineProps({
        displayConfirmModal: {
            type: Boolean
        },
        showSuccessDialog: {
            type: Boolean
        },
        deleted: {
            type: Boolean,
            default: null
        },
        title: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "delete"
        },
        secondDescription: {
            type: String,
            default: ""
        },
        errors: {
            type: Object,
            default: () => ({})
        }
    });

    const emit = defineEmits([
        "closeConfirmModal",
        "closeSuccessDialog",
        "proceedProcess",
        "deleted"
    ]);

    watch(
        () => props.displayConfirmModal,
        (value) => {
            //console.log("ConfirmModal Watch");
            if (props.displayConfirmModal) {
                setConfirmModal(true);
            }
            if (props.showSuccessDialog) {
                setSuccessModalPreview(true);
            }
        }
    );

    watch(
        () => props.deleted,
        (value) => {
            //console.log("deleted: " + value);
            if (value !== null && value) {
                setSuccessModalPreview(true);
                setConfirmModal(false);
            }

            if (value !== null && !value) {
                setSuccessModalPreview(false);
                setConfirmModal(false);
                setErrorModalPreview(true);
            }
        },
        { immediate: true }
    );

    onMounted(() => {
        //console.log("ConfirmModal Mounted");
        //console.log("displayConfirmModal: " + props.displayConfirmModal);
        //console.log("showSuccessDialog" + props.showSuccessDialog);
        //console.log("errorModalPreview: " + errorModalPreview.value);
        //console.log("errors" + props.errors);
        if (props.displayConfirmModal) {
            setConfirmModal(true);
        }
        if (props.showSuccessDialog) {
            setSuccessModalPreview(true);
        }
    });

    const errorModalPreview = ref(false);
    const setErrorModalPreview = (value: boolean) => {
        //console.log("setErrorModalPreview: " + value);
        errorModalPreview.value = value;
    };

    const deleteModalPreview = ref(false);
    const setConfirmModal = (value: boolean) => {
        //console.log("setConfirmModal: " + value);
        deleteModalPreview.value = value;
    };

    const successModalPreview = ref(false);
    const setSuccessModalPreview = (value: boolean) => {
        successModalPreview.value = value;
    };

    const cancelConfirm = () => {
        setConfirmModal(false);
        cancelProcess();
        closeConfirmModal();
        emit("deleted", null);
    };

    const closeConfirmModal = () => {
        //console.log("closeConfirmModal");
        emit("closeConfirmModal", true);
    };

    const proceedProcess = () => {
        //console.log("proceedProcess");
        emit("proceedProcess", true);
    };

    const cancelProcess = () => {
        //console.log("cancelProcess");
        emit("proceedProcess", false);
    };

    const done = () => {
        setErrorModalPreview(false);
        setSuccessModalPreview(false);
        setConfirmModal(false);
        emit("closeConfirmModal", true);
        emit("deleted", null);
    };

    const processDelete = () => {
        proceedProcess();

        if (props.deleted !== null && props.deleted) {
            setSuccessModalPreview(true);
            setConfirmModal(false);
        }

        if (props.deleted !== null && !props.deleted) {
            setSuccessModalPreview(false);
            setConfirmModal(false);
        }

        if (props.deleted === null && props.showSuccessDialog) {
            setSuccessModalPreview(true);
            setConfirmModal(false);
        }

        if (
            props.deleted === null &&
            !props.showSuccessDialog
        ) {
            setSuccessModalPreview(false);
            setConfirmModal(false);
        }
    };

    const sendButtonRef = ref(null);
    const deleteButtonRef = ref(null);

    const isEmptyObject = (obj: {}) => {
        return Object.keys(obj).length === 0;
    };

    type ErrorMessages = {
        [key: string]: string[];
    };
</script>
<template>
    <Dialog
        :initialFocus="deleteButtonRef"
        :open="deleteModalPreview"
        @close="
            () => {
                setConfirmModal(false);
            }
        "
    >
        <Dialog.Panel>
            <div class="p-5 text-center">
                <Lucide
                    class="mx-auto mt-3 h-16 w-16 text-danger"
                    icon="XCircle"
                />
                <div v-if="props.title === ''" class="mt-5 text-3xl">
                    Are you sure?
                </div>
                <div v-else class="mt-5 text-3xl">
                    {{ props.title }}
                </div>

                <div
                    v-if="props.description === ''"
                    class="mt-2 text-slate-500"
                >
                    Do you really want to delete these records?
                    <br />
                    This process cannot be undone.
                </div>

                <div v-else class="mt-2 text-slate-500">
                    {{ props.description }}
                </div>
            </div>
            <div class="px-5 pb-8 text-center">
                <Button
                    class="mr-1 w-24"
                    type="button"
                    variant="outline-secondary"
                    @click="() => cancelConfirm()"
                >
                    Cancel
                </Button>
                <Button
                    ref="{deleteButtonRef}"
                    class="w-24"
                    type="button"
                    variant="danger"
                    @click="
                        () => {
                            processDelete();
                        }
                    "
                >
                    {{
                        props.type === "delete"
                            ? "Delete"
                            : "Confirm"
                    }}
                </Button>
            </div>
        </Dialog.Panel>
    </Dialog>
    <!-- Confirm Delete Modal -->

    <!-- Success Modal -->
    <Dialog
        :open="successModalPreview"
        @close="
            () => {
                done();
            }
        "
    >
        <Dialog.Panel>
            <div class="p-5 text-center">
                <Lucide
                    class="mx-auto mt-3 h-16 w-16 text-success"
                    icon="CheckCircle"
                />
                <div
                    v-if="props.secondDescription === ''"
                    class="mt-2 text-slate-500"
                >
                    You clicked the button!
                </div>
                <div v-else class="mt-2 text-slate-500">
                    {{ props.secondDescription }}
                </div>
            </div>
            <div class="px-5 pb-8 text-center">
                <Button
                    class="w-24"
                    type="button"
                    variant="customPrimary"
                    @click="
                        () => {
                            done();
                        }
                    "
                >
                    Ok
                </Button>
            </div>
        </Dialog.Panel>
    </Dialog>
    <!-- Success Modal -->

    <!-- Error Modal -->
    <Dialog
        :open="errorModalPreview"
        @close="
            () => {
                done();
            }
        "
    >
        <Dialog.Panel>
            <div class="p-5 text-center">
                <Lucide
                    class="mx-auto mt-3 h-16 w-16 text-red-500"
                    icon="AlertCircle"
                />
                <div
                    v-if="
                        props.errors &&
                        isEmptyObject(props.errors)
                    "
                    class="mt-2 text-slate-500"
                >
                    Something went wrong while deleting the selected item.
                </div>

                <div
                    v-else-if="
                        props.errors &&
                        !isEmptyObject(props.errors)
                    "
                    class="mt-2 text-slate-500"
                >
                    {{
                        Object.values(
                            props.errors as ErrorMessages
                        )[0][0]
                    }}
                </div>
            </div>
            <div class="px-5 pb-8 text-center">
                <Button
                    class="w-24"
                    type="button"
                    variant="customPrimary"
                    @click="
                        () => {
                            done();
                        }
                    "
                >
                    Close
                </Button>
            </div>
        </Dialog.Panel>
    </Dialog>
    <!-- Error Modal -->
</template>
