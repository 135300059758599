import { defineStore } from "pinia";
import Http from "../services/Http";
import {
    ShiftBreakTypeInterface,
    ShiftInterface,
    ShiftPaginatedInterface,
} from "../pages/interfaces/shiftInterface";
import EmployeeInterface, {
    EmployeePaginatedInterface,
} from "../pages/interfaces/employeesInterface";
import { WorkSchedule } from "../pages/interfaces/teamsInterface";

interface Employee {
    id: number;
    name: string;
    weekly_schedule: WorkSchedule | null;
    shift: {
        id: number;
        shift: ShiftInterface;
    };
}

interface LaravelApiResource<T> {
    data: T[];
    links: {
        first?: string;
        last?: string;
        prev?: string;
        next?: string;
    };
    meta: {
        current_page?: number;
        from?: number;
        last_page?: number;
        path?: string;
        per_page?: number;
        to?: number;
        total?: number;
    };
}

interface ApiResponse<T> {
    data: T;
    status: T;
}

export const useShiftStore = defineStore("ShiftStore", {
    state: (): {
        allShifts: ShiftInterface[];
        shifts: ShiftPaginatedInterface;
        shift: ShiftInterface;
        shiftBreakTypes: ShiftBreakTypeInterface[];
        employees: EmployeePaginatedInterface;
        shiftEmployees: LaravelApiResource<Employee>;
    } => {
        return {
            allShifts: [] as ShiftInterface[],
            shifts: {} as ShiftPaginatedInterface,
            shift: {} as ShiftInterface,
            shiftBreakTypes: [] as ShiftBreakTypeInterface[],
            employees: {} as EmployeePaginatedInterface,
            shiftEmployees: {} as LaravelApiResource<Employee>,
        };
    },

    actions: {
        async getShifts(params: any): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).get<
                ApiResponse<ShiftPaginatedInterface>
            >("/shifts", { params });

            this.shifts = data;
        },

        async getAllShifts(params: any): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const data = await new Http(baseUrl).get<ShiftInterface[]>(
                `/shifts`,
                {
                    params,
                }
            );

            this.allShifts = data;
        },

        async getShift(id: number): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const data = await new Http(baseUrl).get<ShiftInterface>(
                `/shifts/${id}`
            );

            this.shift = data;
        },

        async addShift(shift: ShiftInterface): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const data = await new Http(baseUrl).post<ShiftInterface>(
                "/shifts",
                shift
            );

            this.shifts.data.push(data);
        },

        async updateShift(shift: ShiftInterface): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const data = await new Http(baseUrl).put<ShiftInterface>(
                `/shifts/${shift.id}`,
                shift
            );

            const index = this.shifts.data.findIndex(
                (item) => item.id === data.id
            );

            this.shifts.data.splice(index, 1, data);
        },

        async deleteShift(id: number): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            await new Http(baseUrl).delete(`/shifts/${id}`);

            const index = this.shifts.data.findIndex((item) => item.id === id);

            this.shifts.data.splice(index, 1);
        },

        async getShiftBreakTypes(): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const response = await new Http(baseUrl).get<
                ApiResponse<ShiftBreakTypeInterface[]>
            >("/shift-break-types");

            this.shiftBreakTypes = response.data;
        },

        async getEmployees(params: any): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).get<
                ApiResponse<EmployeePaginatedInterface>
            >("/employee-shifts", { params });

            this.employees = data;
        },

        async addEmployee(payload: any): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).post<
                ApiResponse<EmployeeInterface>
            >("/employee-shifts", payload);

            // if (
            //     this.employees &&
            //     !this.employees.data?.some(
            //         (employee: EmployeeInterface) => employee.id === data.id
            //     )
            // ) {
            //     this.employees.data.push(data);
            // }
        },

        async removeEmployee(id: number): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            await new Http(baseUrl).delete(`/employee-shifts/${id}`);

            const index = this.employees.data.findIndex(
                (employee: EmployeeInterface) => employee.id === id
            );

            this.employees.data.splice(index, 1);
        },

        async getShiftEmployees(payload: any) {
            const baseUrl = import.meta.env.VITE_API_URL;

            const response = await new Http(baseUrl).get<
                LaravelApiResource<Employee>
            >("/employee-shift-details?" + payload);

            this.shiftEmployees = response;
        },
    },
});
