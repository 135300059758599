import * as Sentry from "@sentry/vue";

import { createApp } from "vue";

import { createPinia } from "pinia";

import PrimeVue from "primevue/config";

import axios from "axios";

import Echo from "laravel-echo";

import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import Pusher from "pusher-js";

import App from "./App.vue";
import router from "./router";

import "./assets/css/app.css";
//import ClientsView from "./pages/ClientsView.vue";

interface Window {
    Pusher: any;
}

(window as any).Pusher = Pusher;
//@ts-ignore
(window as any).Echo = new Echo({
    broadcaster: "pusher",
    key: (window as any).PUSHER_APP_KEY,
    wsHost: window.location.hostname,
    wsPort: (window as any).APP_DEBUG ? 6001 : 6002,
    wssPort: (window as any).APP_DEBUG ? 6001 : 6002,
    forceTLS: !(window as any).APP_DEBUG,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
});
const app = createApp(App, {
    productionTip: true,
});
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$sentry = Sentry;
// app.config.globalProperties.$echo = new Echo({
//     broadcaster: "pusher",
//     key: "local",
//     forceTLS: false,
//     wsHost: "127.0.0.1",
//     wsPort: 6001,
//     encrypted: false,
//     cluster: "mt1",
// });
//app.component("Clients", ClientsView);

Sentry.init({
    app,
    release: import.meta.env.VITE_RELEASE_VERSION,
    dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
            networkDetailAllowUrls: [
                window.location.origin,
                "portal.remoteemployee.com",
                "https://portal.remoteemployee.com/api/*",
                "https://portal-dev2.reops.net",
                "https://portal-dev2.reops.net/api/*",
            ],
        }),
    ],
    tracesSampleRate: 0.5,
    tracePropagationTargets: ["portal.remoteemployee.com", /^\//],
});

app.use(router);
app.use(PrimeVue);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.mount("#app");
