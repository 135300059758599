<script lang="ts" setup>
import { inject } from "vue";
import { Tab as HeadlessTab } from "@headlessui/vue";
import Provider from "./Provider.vue";
import { ProvideList } from "../List.vue";

interface TabProps extends ExtractProps<typeof HeadlessTab> {
    fullWidth?: boolean;
}

const { fullWidth } = withDefaults(defineProps<TabProps>(), {
    fullWidth: true,
});

const list = inject<ProvideList>("list");
</script>

<template>
    <HeadlessTab v-slot="{ selected }" as="template">
        <li>
            <Provider :selected="selected">
                <slot :selected="selected"></slot>
            </Provider>
        </li>
    </HeadlessTab>
</template>
