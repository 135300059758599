import { defineStore } from "pinia";
import axios from "axios";
import { ShiftInterface } from "../pages/interfaces/shiftInterface";

export const useAuthStore = defineStore("AuthStore", {
    state: (): {
        role: string;
        invalidLogin: boolean;
        authUser: {
            id: number;
            name: string;
            email: string;
            two_factor: string;
            two_factor_secret: string;
            is_auto_password: boolean;
            is_employee: boolean;
        };
        activatedPermissionsName: any[];
        qrSvg: string;
        token: string;
        permissions: any[];
        myTeam: any[];
        isLoggedIn: boolean;
        invalid2FA: boolean;
        impersonator: string;
        user: any[];
        is2FAenabled: boolean;
        impersonated: string;
        email: string;
        myClient: any[];
        myShift: ShiftInterface;
        showGlobalModal: boolean;
        globalModalTitle: string;
        globalModalMessage: string;
        lateNotification: boolean;
    } => ({
        user: [],
        isLoggedIn: false,
        invalidLogin: false,
        token: "",
        email: "",
        authUser: {
            id: 0,
            name: "",
            email: "",
            two_factor: "",
            two_factor_secret: "",
            is_auto_password: false,
            is_employee: false,
        },
        role: "",
        permissions: [],
        activatedPermissionsName: [],
        myTeam: [],
        myClient: [],
        myShift: {
            id: 0,
            name: "",
            timezone_id: "",
            shift_active_start_date: "",
            start_time: "",
            end_time: "",
            total_hours: 0,
            shift_breaks: [],
            shift_overtimes: [],
            used_on_holidays: false,
            used_on_restdays: false,
            work_days: [],
        },
        is2FAenabled: false,
        invalid2FA: false,
        qrSvg: "",
        impersonated: "",
        impersonator: "",
        showGlobalModal: false,
        globalModalTitle: "",
        globalModalMessage: "",
        lateNotification: false,
    }),
    actions: {
        async login(email: string, password: string) {
            try {
                await axios.get(
                    import.meta.env.VITE_APP_URL + "/sanctum/csrf-cookie"
                );
                const response = await axios.post(
                    import.meta.env.VITE_APP_URL + "/login",
                    {
                        email: email,
                        password: password,
                    }
                );
                if (response?.status === 200) {
                    console.log("login response");
                    if (response.data.two_factor === true) {
                        return { status: "two_factor_required" };
                    } else {
                        return { status: "success", data: response.data };
                    }
                }
            } catch (error) {
                console.error(error);
                return { status: "error", error: error };
            }
        },
        validate2FA(code: any) {
            axios
                .get(import.meta.env.VITE_APP_URL + "/sanctum/csrf-cookie")
                .then((response) => {
                    axios
                        .post(
                            import.meta.env.VITE_APP_URL +
                                "/two-factor-challenge",
                            {
                                code: code,
                            }
                        )
                        .then((response) => {
                            console.log("response");
                            console.log(response);
                            if (response.status === 204) {
                                this.isLoggedIn = true;
                                this.invalidLogin = false;
                                this.generateToken();
                            }
                        })
                        .catch((error) => {
                            console.log("two error");
                            this.invalid2FA = true;
                        });
                });
        },
        generateToken() {
            console.log("generate token");
            let options = {
                method: "POST",
                url: import.meta.env.VITE_API_URL + "/tokens/create",
                data: { email: this.email },
            };
            axios
                .request(options)
                .then((response) => {
                    console.log(response.data);
                    this.token = response.data.token;
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        logout() {
            axios
                .get(import.meta.env.VITE_APP_URL + "/sanctum/csrf-cookie")
                .then((response) => {
                    let options = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        url: import.meta.env.VITE_APP_URL + "/logout",
                    };
                    axios
                        .request(options)
                        .then((response) => {
                            console.log(response.status);
                            if (response.status === 204) {
                                this.isLoggedIn = false;
                            }
                        })
                        .catch(function (error) {
                            console.error(error);
                        });
                });
        },
        enable2FA() {
            axios
                .get(import.meta.env.VITE_APP_URL + "/sanctum/csrf-cookie", {
                    withCredentials: true,
                })
                .then((response) => {
                    const AuthStore = useAuthStore();
                    let options = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + AuthStore.token,
                        },
                        withCredentials: true,
                        url:
                            import.meta.env.VITE_APP_URL +
                            "/user/two-factor-authentication",
                    };
                    axios.request(options).then((response) => {
                        console.log("2fa: " + response.status);
                        if (response.status === 200) {
                            this.getLoggedInUserDetails();
                        }
                    });
                });
        },
        disable2FA() {
            axios
                .get(import.meta.env.VITE_APP_URL + "/sanctum/csrf-cookie", {
                    withCredentials: true,
                })
                .then((response) => {
                    const AuthStore = useAuthStore();
                    let options = {
                        method: "DELETE",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + AuthStore.token,
                        },
                        withCredentials: true,
                        url:
                            import.meta.env.VITE_APP_URL +
                            "/user/two-factor-authentication",
                    };
                    axios.request(options).then((response) => {
                        console.log("2fa: " + response.status);
                        if (response.status === 200) {
                            this.getLoggedInUserDetails();
                        }
                    });
                });
        },
        async getLoggedInUserDetails() {
            if (this.authUser.id !== 0) {
                return;
            }

            try {
                const AuthStore = useAuthStore();
                await axios.get(
                    import.meta.env.VITE_APP_URL + "/sanctum/csrf-cookie",
                    {
                        withCredentials: true,
                    }
                );

                let options = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + AuthStore.token,
                    },
                    withCredentials: true,
                    url: import.meta.env.VITE_API_URL + "/get-user-details",
                };

                const response = await axios.request(options);
                this.authUser = response.data.user;
                this.email = response.data.user.email;
                this.qrSvg = response.data.twoFactorQrCodeSvg;
                this.role = response.data.role;
                this.permissions = response.data.permissions;
                this.activatedPermissionsName =
                    response.data.activatedPermissionsName;
                this.myTeam = response.data.myTeam;
                this.myClient = response.data.myClient;
                this.myShift = response.data.myShift;
                this.lateNotification = response.data.lateNotification;
            } catch (error) {
                if (
                    //@ts-ignore
                    error.response &&
                    //@ts-ignore
                    error.response.data.status === "config error"
                ) {
                    this.showGlobalModal = true;
                    //@ts-ignore
                    this.globalModalMessage = error.response.data.error;
                }
            }
        },
        async impersonate(id: string) {
            try {
                const AuthStore = useAuthStore();
                let options = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + AuthStore.token,
                    },
                    url:
                        import.meta.env.VITE_API_URL +
                        "/" +
                        id +
                        "/impersonate",
                };
                await axios.request(options).then((response) => {
                    console.log("impersonate called", response.data);

                    this.impersonated = response.data.impersonated;
                    this.impersonator = response.data.impersonator;
                    return response.data;
                });
            } catch (error) {
                console.log("impersonate::error");
                console.log(error);
            }
        },
        async leaveImpersonate() {
            try {
                const AuthStore = useAuthStore();
                let options = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + AuthStore.token,
                    },
                    url: import.meta.env.VITE_API_URL + "/leave-impersonate",
                };
                await axios.request(options).then((response) => {
                    console.log("impersonate called");
                    this.impersonated = "";
                    this.impersonator = "";
                    window.location.reload();
                });
            } catch (error) {
                console.log("impersonate::error");
                console.log(error);
            }
        },
        hasPermission(permission: string) {
            return this.activatedPermissionsName.includes(permission);
        },
        resetGlobalModal() {
            this.showGlobalModal = false;
            this.globalModalTitle = "";
            this.globalModalMessage = "";
        },
        async changePassword(
            currentPassword: string,
            password: string,
            confirmPassword: string,
            skipCurrentPassword: boolean,
        ) {
            await axios.get(
                import.meta.env.VITE_APP_URL + "/sanctum/csrf-cookie"
            );
            await axios.put(import.meta.env.VITE_APP_URL + "/user/password", {
                current_password: currentPassword,
                password: password,
                password_confirmation: confirmPassword,
                skip_current_password: skipCurrentPassword,
            });
        },
        async skipChangePassword() {
            const baseUrl = import.meta.env.VITE_API_URL;
            await axios.get(
                import.meta.env.VITE_APP_URL + "/sanctum/csrf-cookie"
            );
            await axios.put(baseUrl + "/user/skip-password-change");
        },
        async forgotPassword(email: string) {
            await axios.get(
                import.meta.env.VITE_APP_URL + "/sanctum/csrf-cookie"
            );
            return await axios.post(import.meta.env.VITE_APP_URL + "/user/forgot-password", {
                email: email,
            });
        },
        async resetPassword(
            token: string,
            email: string,
            password: string,
            password_confirmation: string
        ) {
            await axios.get(
                import.meta.env.VITE_APP_URL + "/sanctum/csrf-cookie"
            );
            return await axios.post(import.meta.env.VITE_APP_URL + "/reset-password", {
                token: token,
                email: email,
                password: password,
                password_confirmation: password_confirmation
            });
        },
        async verifyToken(token: string, email: string) {
            await axios.get(
                import.meta.env.VITE_APP_URL + "/sanctum/csrf-cookie"
            );
            return await axios.post(import.meta.env.VITE_APP_URL + "/user/verify-token", {
                token: token,
                email: email,
            });
        }
    },
});
