import { defineStore } from "pinia";
import axios from "axios";
import moment from "moment";
import Http from "../services/Http";
import * as Sentry from "@sentry/browser";

interface State {
    tickets: { due_by: string }[];
    open: number;
    overdue: number;
    requestResponse: any[];
    requestStatus: string;
    requestError: any[];

    [key: string]: any;
}

export const useFreskdeskStore = defineStore("FreshdeskStore", {
    state: (): State => ({
        tickets: [],
        open: 0,
        overdue: 0,
        requestResponse: [],
        requestStatus: "",
        requestError: [],
    }),
    getters: {},
    actions: {
        async createTicket(form: any) {
            try {
                const response = await axios.post(
                    process.env.MIX_API_URL + "/create-ticket",
                    form
                );
                this.requestResponse = response.data.data;
                this.requestStatus = response.data.status;
            } catch (error) {
                console.log(error);
            }
        },
        async fill() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<any>("/open-tickets");

                this.tickets = response.data;
                this.open = response.data.total;
                this.overdue = response.data.overdue;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        getTotalTickets<K extends keyof State>(
            compare: (ticketDueDate: string, today: string) => boolean,
            prop: K
        ) {
            const today = moment(new Date()).format("YYYY-MM-DD");
            this[prop as keyof State] = this.tickets.reduce((total, ticket) => {
                const ticketDueDate = moment(ticket.due_by).format(
                    "YYYY-MM-DD"
                );
                if (compare(ticketDueDate, today)) {
                    return total + 1;
                }
                return total;
            }, 0) as State[K];
        },
    },
});
