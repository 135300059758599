import { EmployeeStatusHistoryInterface } from "@/pages/interfaces/employeesInterface";
import StatusTypeInterface from "@/pages/interfaces/statusTypeInterface";

import * as Sentry from "@sentry/browser";

import { defineStore } from "pinia";

import axios, { AxiosRequestConfig } from "axios";

import _ from "lodash";

import moment from "moment-timezone";

import Http from "../services/Http";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import TicketTypeInterface from "../pages/interfaces/ticketTypeInterface";

import { useAuthStore } from "./authStore";
interface HelperStoreState {
    employeeStatusHistories: any[];
    statusTypes: StatusTypeInterface[];
    invoiceSubscriptions: any[];
    attachmentTypes: any[];
    positionAttachmentTypes: any[];
    timezones: any[];
    teamRoles: any[];
    ticketTypes: TicketTypeInterface[];
    userTeam: any[];
    countries: any[];
    billingStates: any[];
    states: any[];
    shiftBreakTypes: any[];
    $sentry: typeof Sentry | null;
    serverTime: string;
}

export const useHelperStore = defineStore("HelperStore", {
    state: (): HelperStoreState => {
        return {
            employeeStatusHistories: [],
            statusTypes: [],
            invoiceSubscriptions: [],
            positionAttachmentTypes: [],
            attachmentTypes: [],
            timezones: [],
            teamRoles: [],
            ticketTypes: [],
            userTeam: [],
            countries: [],
            billingStates: [],
            states: [],
            shiftBreakTypes: [],
            $sentry: null,
            serverTime: "",
        };
    },
    getters: {},
    actions: {
        async getServerTime() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL,
                ).get<ApiResponseInterface<any>>(
                    "/current-time",
                );
                this.serverTime = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (
                        this.$sentry as typeof Sentry
                    ).captureException(error);
                }
                console.log(error);
            }
        },
        async fillShiftBreakTypes() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL,
                ).get<ApiResponseInterface<any>>(
                    "/shift-break-types",
                );
                this.shiftBreakTypes = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (
                        this.$sentry as typeof Sentry
                    ).captureException(error);
                }
                console.log(error);
            }
        },
        async fillInvoiceSubscriptions() {
            try {
                const AuthStore = useAuthStore();
                let options: AxiosRequestConfig = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + AuthStore.token,
                    },
                    url:
                        import.meta.env.VITE_API_URL +
                        "/get-invoice-subscriptions",
                };
                const response =
                    await axios.request(options);
                this.invoiceSubscriptions =
                    response.data.invoice_subscriptions;
            } catch (error) {
                console.log(error);
            }
        },
        async fillAttachmentTypes() {
            try {
                const AuthStore = useAuthStore();
                let options: AxiosRequestConfig = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + AuthStore.token,
                    },
                    url:
                        import.meta.env.VITE_API_URL +
                        "/get-attachment-types",
                };
                const response =
                    await axios.request(options);
                this.attachmentTypes =
                    response.data.attachment_types;
            } catch (error) {
                console.log(error);
            }
        },
        async fillStatusTypes() {
            try {
                const AuthStore = useAuthStore();
                let options: AxiosRequestConfig = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + AuthStore.token,
                    },
                    url:
                        import.meta.env.VITE_API_URL +
                        "/get-status-types",
                };
                const response =
                    await axios.request(options);
                this.statusTypes =
                    response.data.status_types;
            } catch (error) {
                console.log(error);
            }
        },
        async fillPositionAttachmentTypes() {
            try {
                const AuthStore = useAuthStore();
                let options: AxiosRequestConfig = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + AuthStore.token,
                    },
                    url:
                        import.meta.env.VITE_API_URL +
                        "/get-position-attachment-types",
                };
                const response =
                    await axios.request(options);
                this.positionAttachmentTypes =
                    response.data.attachment_types;
            } catch (error) {
                console.log(error);
            }
        },

        async fillEmployeeStatusHistories(
            employeeId: number,
        ): Promise<void> {
            try {
                const AuthStore = useAuthStore();
                let options: AxiosRequestConfig = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + AuthStore.token,
                    },
                    url:
                        import.meta.env.VITE_API_URL +
                        `/get-employee-status-histories?employee_id=${employeeId}`,
                };
                const response =
                    await axios.request(options);
                this.employeeStatusHistories =
                    response.data.status_histories;
            } catch (error) {
                console.log(error);
            }
        },
        async fillStates() {
            try {
                const AuthStore = useAuthStore();
                let options: AxiosRequestConfig = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + AuthStore.token,
                    },
                    url:
                        import.meta.env.VITE_API_URL +
                        "/get-us-states",
                };
                const response =
                    await axios.request(options);
                this.states = response.data.states;
                this.billingStates = response.data.states;
            } catch (error) {
                console.log(error);
            }
        },
        async fillStatesById(
            params: any,
            forBilling = false,
        ) {
            try {
                const AuthStore = useAuthStore();
                let options: AxiosRequestConfig = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + AuthStore.token,
                    },
                    url:
                        import.meta.env.VITE_API_URL +
                        "/get-states-by-id",
                    params: params,
                };
                const response =
                    await axios.request(options);
                if (forBilling) {
                    this.billingStates =
                        response.data.states;
                } else {
                    this.states = response.data.states;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fillCountries() {
            try {
                const AuthStore = useAuthStore();
                let options = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + AuthStore.token,
                    },
                    url:
                        import.meta.env.VITE_API_URL +
                        "/get-countries",
                };
                axios.request(options).then((response) => {
                    this.countries =
                        response.data.countries;
                });
            } catch (error) {
                console.log(error);
            }
        },
        async fillTeamRoles() {
            try {
                const AuthStore = useAuthStore();
                let options = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + AuthStore.token,
                    },
                    url:
                        import.meta.env.VITE_API_URL +
                        "/get-team-roles",
                };
                axios.request(options).then((response) => {
                    this.teamRoles =
                        response.data.team_roles;
                });
            } catch (error) {
                console.log(error);
            }
        },
        getTeamRole(id: number) {
            const teamRole = _.find(
                this.teamRoles,
                (role) => {
                    return role.id === id;
                },
            );
            if (typeof teamRole === "undefined") {
                return {
                    id: "",
                    name: "",
                };
            } else {
                return teamRole;
            }
        },
        async fillTimezones() {
            try {
                const AuthStore = useAuthStore();
                let options = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + AuthStore.token,
                    },
                    url:
                        import.meta.env.VITE_API_URL +
                        "/get-timezones",
                };
                axios.request(options).then((response) => {
                    this.timezones =
                        response.data.timezones;
                });
            } catch (error) {
                console.log(error);
            }
        },
        getTimezone(id: string | number) {
            const timezone = _.find(
                this.timezones,
                (zone) => {
                    return zone.id === id;
                },
            );

            if (typeof timezone === "undefined") {
                return {
                    country_id: "",
                    id: "",
                    name: "",
                };
            } else {
                return timezone;
            }
        },

        getTimezoneOffset(id: string | number) {
            const timezone = _.find(
                this.timezones,
                (zone) => {
                    return zone.id === id;
                },
            );

            if (typeof timezone === "undefined") {
                return "";
            } else {
                const offset =
                    moment.tz(timezone.name).utcOffset() /
                    60;

                return offset >= 0
                    ? `+${offset}`
                    : `${offset}`;
            }
        },

        getTimezoneByTz(tz: string) {
            const timezone = _.find(
                this.timezones,
                (zone) => {
                    return zone.timezone === tz;
                },
            );

            if (typeof timezone === "undefined") {
                return {
                    country_id: "",
                    id: "",
                    name: "",
                };
            } else {
                return timezone.name;
            }
        },
        async fillTicketTypes() {
            try {
                const AuthStore = useAuthStore();
                let options = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + AuthStore.token,
                    },
                    url:
                        import.meta.env.VITE_API_URL +
                        "/get-ticket-types",
                };
                axios.request(options).then((response) => {
                    // console.log(response.data.ticketTypes);
                    this.ticketTypes =
                        response.data.ticketTypes;
                });
            } catch (error) {
                console.log(error);
            }
        },
        // getTicketType(id: number) {
        //     const ticketType = _.find(
        //         this.ticketTypes,
        //         (tickType) => {
        //             return tickType.id == id;
        //         },
        //     );
        //     console.log("tickt", ticketType);
        //     if (typeof ticketType === "undefined") {
        //         return {
        //             id: "",
        //             name: "",
        //             f_type: "",
        //             f_status_id: "",
        //             f_priority_id: "",
        //             sequence: [],
        //         };
        //     } else {
        //         return ticketType;
        //     }
        // },
        getTicketType(id: number) {
      
            const defaultTicketType = {
              id: "",
              name: "",
              f_type: "",
              f_status_id: "",
              f_priority_id: "",
              sequence: [],
            };
      
            const ticketType = _.find(
              this.ticketTypes,
              tickType => tickType.id === id
            );

            console.log("ticket type", ticketType || defaultTicketType);
      
            return ticketType || defaultTicketType;
            
        },
        getTicketTypeByName(keyword = "") {
            if (keyword === "") {
                return this.ticketTypes;
            }

            return Object.values(this.ticketTypes)
                .filter((a) => a.code !== null)
                .filter(
                    (a) =>
                        a.name
                            .toLowerCase()
                            .includes(
                                keyword.toLowerCase(),
                            ) ||
                        a.code
                            .toString()
                            .includes(keyword.toString()),
                );
        },
        getTicketStatus(id: number) {
            let status = "";
            switch (id) {
                case 2:
                    status = "Open";
                    break;
                case 3:
                    status = "Pending";
                    break;
                case 4:
                    status = "Resolved";
                    break;
                case 5:
                    status = "Closed";
                    break;
                default:
                    status = "N/A";
            }
            return status;
        },
        getTicketPriority(id: string) {
            let priority = "";
            switch (id) {
                case "1":
                    priority = "Low";
                    break;
                case "2":
                    priority = "Medium";
                    break;
                case "3":
                    priority = "High";
                    break;
                case "4":
                    priority = "Urgent";
                    break;
                default:
                    priority = "N/A";
            }
            return priority;
        },
        getTicketDetailsBodySequence(ticketType: string) {
            let sequence: string[] = [];
            switch (ticketType) {
                case "New Candidate Search Ticket":
                    sequence = [
                        "Date Filed",
                        "Role",
                        "Expertise",
                        "Salary Range in Peso",
                        "Shift",
                        "Work Setup",
                        "Client",
                        "Team Leader",
                        "Client Website",
                        "Name of Client POC",
                        "Position of Client POC",
                        "Currency",
                        "Job Description",
                        "Responsibilities / Job Duties",
                        "Qualifications",
                        "Comments",
                    ];
                    break;
                case "Employee Finance Disputes":
                    sequence = [
                        "Date Filed",
                        "Concerned Payout Period",
                        "Type of Dispute",
                        "Other concerns",
                        "Concern Date",
                        "Amount of Salary Dispute",
                        "Nature of the Claim",
                        "Team Leaders",
                        "Approver",
                        //'Attachment of Payslip',
                        //'Attachment/s of Proof of claim',
                    ];
                    break;
                case "Employee Loan and Deductions":
                    sequence = [
                        "Date Filed",
                        "Government Agency",
                        "Reference Number",
                        "Date Issued",
                        "Loan Type",
                        "Start of Loan Amortization",
                        "Payment Scheme",
                        "Amount of Loan",
                        "Preferred amount of Monthly amortization",
                        "Loan Term",
                    ];
                    break;
                case "Certificate of Employment":
                    sequence = [
                        "Date Filed",
                        "Reason for Request",
                        "Should include Salary",
                        "Should include Position",
                        "Should include Address",
                    ];
                    break;
                case "Employee Leave Request":
                    sequence = [
                        "Date Filed",
                        "Leave Type",
                        "Approver",
                        "Team Leaders",
                        "Dates Requested",
                        "Reason",
                    ];
                    break;
                case "ITR Request":
                    sequence = [
                        "Date Filed",
                        "Reason for Request",
                        "ITR Fiscal Year",
                    ];
                    break;
                case "Certified True Copy Payslip":
                    sequence = [
                        "Date Filed",
                        "Concerned Payout Periods",
                        "Reason",
                    ];
                    break;
                case "Overtime Request":
                    sequence = [
                        "Date Filed",
                        "Dates Requested",
                        "Approver",
                        "Team Leaders",
                        "Department",
                        "Number of hours",
                        "Type of overtime",
                        "Reason",
                    ];
                    break;
                case "Salary Increase Request":
                    sequence = [
                        "Date Filed",
                        "Employee Name",
                        "Reason",
                    ];
                    break;
                case "Employee Feedback Form":
                    sequence = [
                        "Date Filed",
                        "Feedback Type",
                        "Details",
                    ];
                    break;
                case "Employee Tardiness Request":
                    sequence = [
                        "Date Filed",
                        "Employee Name",
                        "Reason",
                    ];
                    break;
                case "Ticket for CCTV recordings":
                    sequence = [
                        "Date Filed",
                        "Priority",
                        "Dates Requested",
                        "Start Time",
                        "End Time",
                        "Rooms",
                        "Reason",
                        "Remarks",
                    ];
                    break;
                case "IT - Equipment Request":
                    sequence = [
                        "Date Filed",
                        "Priority",
                        "Purpose",
                        "List of Equipment Required",
                        "Date Required By",
                    ];
                    break;
                case "IT - Support Request":
                    sequence = [
                        "Priority",
                        "Issue Type",
                        "Issue Description",
                    ];
                    break;
                case "Occurrence Points Request":
                    sequence = [
                        "Date Filed",
                        "Dates Requested",
                        "Employee Name",
                        "Purpose for Request",
                        "Detailed Reason",
                    ];
                    break;
                case "Complete 201 File update":
                    sequence = [
                        "Date Filed",
                        "What field needs to be updated",
                        "Detailed Reason",
                    ];
                    break;
                case "Ticket for Log ins activation":
                    sequence = [
                        "Date Filed",
                        "Name",
                        "Role",
                        "Shift",
                        "Client",
                        "Client POC",
                        "Contact",
                        "Personal Email",
                        "Team",
                        "WFO/WFH",
                        "PC Set-UP",
                        "Start Date",
                        "Onboarding Date",
                    ];
                    break;
                case "Ticket for Log ins deactivation":
                    sequence = [
                        "Date Filed",
                        "Employee",
                        "Reason for Deactivation",
                        "Detailed Reason",
                        "Status",
                    ];
                    break;
                case "Employment Contract Request":
                    sequence = ["Date Filed", "Reason"];
                    break;
                case "Employee Team Change Request":
                    sequence = [
                        "Date Filed",
                        "Employee",
                        "Client",
                        "Team",
                    ];
                    break;
                case "Occurrence Points Dispute":
                    sequence = [
                        "Date Filed",
                        "Dates Requested",
                        "Detailed Reason",
                    ];
                    break;
                case "Deactivation":
                    sequence = [
                        "Employee",
                        "Work Email",
                        "Personal Email",
                        "Personal Phone",
                        "Client",
                        "Client POC Email",
                        "Client POC Name",
                        "Sales Executive",
                        "Team Leader",
                        "End Date",
                        "No of days to render",
                        "Replacement needed?",
                        "Reason",
                        "Detailed reason",
                        "Notes",
                    ];

                    break;
                case "Shift Change Request":
                    sequence = [
                        "Employee",
                        "Shift",
                        "Effective From",
                        "Effective To",
                        "Notes",
                    ];
                    break;
                case "Employee Attendance Disputes":
                    sequence = [
                        "Date Filed",
                        "Concerned Payout Period",
                        "Type of Dispute",
                        "Other concerns",
                        "Concern Date",
                        // "Amount of Salary Dispute",
                        "Nature of the Claim",
                        "Team Leaders",
                        "Approver",
                        //'Attachment of Payslip',
                        //'Attachment/s of Proof of claim',
                    ];
                    break;
                case "WFH PC Setup Request":
                    sequence = [
                        "Date Filed",
                        "Date of Pick Up",
                        "Time of Pick Up",
                        "WFH Setup",
                        "Reason",
                        "Start Date",
                        "End Date",
                    ];
                    break;
                case "Workstation Relocation Request":
                    sequence = [
                        "Current workstation location",
                        "Desired new workstation location",
                        "Reason for relocation",
                        "Preferred relocation date",
                        "Special requirements or considerations",
                        "Team Leaders",
                        "Approver",
                    ];
                    break;
                case "Temporary Access Card":
                    sequence = [
                        "Reason for request",
                        "Preferred issuance date",
                        "Special requirements or considerations",
                        "Team Leaders",
                        "Approver",
                    ];
                    break;
                case "Employee Late/Absent Notification":
                    sequence = [
                        "Date Filed",
                        "Reason for Request",
                        "Remarks",
                    ];
                    break;
                default:
                    sequence = [];
            }
            return sequence;
        },
    },
});
