import { defineStore } from "pinia";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import { CurrencyInterface } from "../pages/interfaces/currencyInterface";
import Http from "../services/Http";

const baseUrl = import.meta.env.VITE_API_URL;
export const useCurrencyStore = defineStore("CurrencyStore", {
    state: (): {
        currencies: ApiResponseInterface<CurrencyInterface[]>;
    } => {
        return {
            currencies: {} as ApiResponseInterface<CurrencyInterface[]>,
        };
    },

    actions: {
        async getCurrencies(): Promise<void> {
            const { data } = await new Http(baseUrl).get<
                ApiResponseInterface<CurrencyInterface[]>
            >("/currency");

            this.currencies.data = data;
        },
    },
});
