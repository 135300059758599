<script setup lang="ts">
    import {
        cva,
        type VariantProps
    } from "class-variance-authority";
    import { twMerge } from "tailwind-merge";

    type ButtonProps = VariantProps<typeof button>;

    const props = withDefaults(
        defineProps<{
            variant?: ButtonProps["variant"];
            size?: ButtonProps["size"];
            kind?: ButtonProps["kind"];
            active?: ButtonProps["active"];
            type?: "button" | "submit" | "reset";
            disabled?: boolean;
        }>(),
        {
            variant: "primary",
            kind: "button",
            size: "sm",
            active: false,
            type: "button"
        }
    );

    const button = cva(
        [
            "flex gap-2 font-medium justify-center items-center transition-all ease-in duration-300"
        ],
        /*tw*/
        {
            variants: {
                variant: {
                    primary:
                        "border-2 border-custom-primary bg-custom-primary text-white hover:bg-[#11213A] hover:text-white",
                    secondary:
                        "border-2 border-custom-tertiary bg-custom-tertiary text-white hover:bg-cyan-700 hover:border-cyan-700",
                    outline:
                        "border-2 border-custom-primary bg-white text-custom-primary hover:bg-custom-primary hover:text-white",
                    ghost: "text-custom-tertiary bg-white hover:bg-gray-100",
                    tab: "bg-background-light text-placeholder-text hover:bg-custom-tertiary hover:text-white",
                    danger: "border-2 border-custom-muted-red bg-custom-muted-red text-white hover:bg-[#BD2C2C] hover:border-[#BD2C2C] focus:border-[#BD2C2C] focus:ring-0",
                    success:
                        "border-2 border-custom-success bg-custom-success text-white hover:bg-green-700 hover:border-green-700",
                    information:
                        "border-2 border-custom-information bg-custom-information text-white hover:bg-yellow-600 hover:border-yellow-600",
                    disabled:
                        "bg-gray-200 text-gray-400 cursor-not-allowed border-2 border-bg-gray-200",
                    "dark-gray":
                        "border-2 border-[#69758A] bg-[#69758A] text-white hover:bg-[#444E5E] hover:border-[#444E5E] hover:text-white",
                    "light-gray":
                        "border-2 border-[#B2BBCB] bg-[#B2BBCB] text-white hover:bg-[#7E8695] hover:text-white hover:border-[#7E8695]",
                    "light-blue":
                        "border-2 border-custom-secondary bg-custom-secondary text-white hover:bg-[#305B73] hover:border-[#305B73] hover:text-white"
                },
                size: {
                    xs: "",
                    sm: "text-sm py-2 px-3 h-min",
                    md: "text-base py-2 px-5 h-min",
                    lg: "text-base py-3 px-6 h-min"
                },
                kind: {
                    button: "rounded-md",
                    icon: "rounded-full",
                    padless:
                        "bg-transparent border-none hover:bg-transparent"
                },
                active: {
                    true: "",
                    false: ""
                }
            },
            compoundVariants: [
                {
                    size: "xs",
                    kind: "icon",
                    class: "p-1 h-min"
                },
                {
                    size: "sm",
                    kind: "icon",
                    class: "p-2 h-min"
                },
                {
                    size: "md",
                    kind: "icon",
                    class: "p-3 h-min"
                },
                {
                    size: "lg",
                    kind: "icon",
                    class: "p-5 h-min"
                },
                {
                    variant: "tab",
                    active: true,
                    class: "h-full px-5 py-4 text-black bg-white rounded-none hover:bg-white hover:text-black"
                },
                {
                    variant: "tab",
                    active: false,
                    class: "h-full px-5 py-4 rounded-none"
                },
                {
                    kind: "padless",
                    variant: "ghost",
                    class: "py-1 px-1 h-min text-white hover:text-gray-400"
                },
                {
                    kind: "padless",
                    variant: "primary",
                    class: "py-1 px-1 h-min text-custom-primary hover:text-[#11213A]"
                },
                {
                    kind: "padless",
                    variant: "danger",
                    class: "py-1 px-1 h-min text-custom-danger hover:text-[#6C0C0C]"
                },
                {
                    kind: "padless",
                    variant: "secondary",
                    class: "py-1 px-1 h-min text-custom-tertiary hover:text-[#0E6679]"
                },
                {
                    kind: "padless",
                    variant: "success",
                    class: "py-1 px-1 h-min text-custom-success hover:text-[#3D7B17]"
                }
            ]
        }
    );
</script>

<template>
    <button
        :class="
            twMerge(button({ variant, size, kind, active }))
        "
        :type="type"
        :disabled="disabled"
    >
        <slot></slot>
    </button>
</template>
