<script lang="ts" setup>
    import Modal from "@/pages/components/Modal.vue";
    import Button from "@/base-components/Button";
    import {
        provide,
        reactive,
        ref,
        toRefs,
        defineEmits
    } from "vue";
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import { useEmployeesStore } from "@/stores/employeesStore";
    import Notification, {
        NotificationElement
    } from "@/base-components/Notification";
    import Lucide from "@/base-components/Lucide";
    import SubmitButton from "@/pages/components/SubmitButton.vue";

    type ErrorMessages = {
        [key: string]: string[];
    };

    const emit = defineEmits(["fetchEmployees"]);

    const dialog = ref(false);
    const uploading = ref(false);
    const EmployeeStore = useEmployeesStore();
    const backendValidationErrors = ref({});
    const errorNotification = ref<NotificationElement>();
    const successNotification = ref<NotificationElement>();
    // @ts-ignore
    const $externalResults = ref<Record<string, any> | []>({});
    const inputFile = ref<HTMLInputElement>();

    const uploadFile = (e: Event) => {
        const target = e.target as HTMLInputElement;

        if (!target.files) {
            return;
        }

        form.attachment = target.files[0];
    };

    const form = reactive({
        attachment: null as File | null
    });

    const rules = {
        attachment: {
            required
        }
    };
    //@ts-ignore
    //prettier-ignore
    const validate = useVuelidate(rules, toRefs(form), {$externalResults});

    const open = () => {
        dialog.value = true;
    };

    const close = () => {
        dialog.value = false;
        $externalResults.value = {};
    };

    const download = async () => {
        await EmployeeStore.downloadEmployeeInvoiceInformationImportTemplate();
    };

    const errorNotificationToggle = () => {
        // Show notification
        errorNotification.value?.showToast();
    };
    provide(
        "bind[errorNotification]",
        (el: NotificationElement) => {
            errorNotification.value = el;
        }
    );
    const showErrorsNotification = () => {
        errorNotificationToggle();
    };

    const successNotificationToggle = () => {
        successNotification.value?.showToast();
    };
    provide(
        "bind[successNotification]",
        (el: NotificationElement) => {
            successNotification.value = el;
        }
    );
    const showSuccessNotification = () => {
        successNotificationToggle();
    };

    const onClickUpload = async () => {
        uploading.value = true;
        validate.value.$reset();
        validate.value.$clearExternalResults();
        validate.value.$touch();

        if (validate.value.$invalid) {
            backendValidationErrors.value = {
                message: ["Incomplete or Missing required data"]
            };

            showErrorsNotification();
            uploading.value = false;
            return;
        }

        if (!validate.value.$invalid) {
            try {
                const fd = new FormData();
                fd.append(
                    "attachment",
                    form.attachment as File
                );

                await EmployeeStore.importEmployeeInvoiceInformationImportTemplate(
                    fd
                );

                close();
                showSuccessNotification();
                emit("fetchEmployees");
            } catch (error: any) {
                // @ts-ignore
                inputFile.value.value = null;
                form.attachment = null;
                validate.value.$reset();
                $externalResults.value =
                    error?.response?.data?.errors || {};

                if (
                    $externalResults.value &&
                    Object.keys($externalResults.value).length >
                        0 &&
                    Object.values(
                        $externalResults.value as ErrorMessages
                    ).some((a: any) =>
                        a[0].includes("Skipping")
                    )
                ) {
                    emit("fetchEmployees");
                }
            } finally {
                uploading.value = false;
            }
        }
    };
</script>

<template>
    <Modal :open="dialog" size="xl">
        <template #trigger>
            <Button
                id="importEmployee"
                class="mr-2 shadow-md"
                variant="customPrimary"
                @click="open"
            >
                Import Invoice Information
            </Button>
        </template>

        <template #content>
            <div
                class="mb-4 flex rounded-lg bg-blue-100 p-4 text-sm text-blue-800
                    dark:bg-gray-800 dark:text-blue-400"
                role="alert"
            >
                <svg
                    class="me-3 mt-[2px] inline h-4 w-4 flex-shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
                    />
                </svg>
                <span class="sr-only">Info</span>
                <div class="ml-2">
                    <span class="font-medium">
                        Please be advised that if the employee does not have the
                        following information, it will be skipped:
                    </span>
                    <ul class="mt-2 list-inside list-disc">
                        <li>Employee ID</li>
                        <li>Base Cost</li>
                        <li>Non-Refundable</li>
                        <li>RE Fee</li>
                        <li>Start Date</li>
                    </ul>

                    <div class="mt-4">
                        <span class="font-medium">
                            The following fields should follow the validations
                            below:
                        </span>
                        <ul class="mt-2 list-inside list-disc">
                            <li>Base Cost must be numeric</li>
                            <li>Non-Refundable must be numeric</li>
                            <li>RE Fee must be numeric</li>
                            <li>
                                Start Dates must be:
                                <ul
                                    class="mt-2 list-inside list-square pl-[revert]"
                                >
                                    <!-- <li>
                                        In
                                        <span class="font-bold">
                                            mm/dd/YYYY
                                        </span>
                                        format (e.g. 08/16/2024)
                                    </li> -->
                                    <li>
                                        Greater or equal to the first day of the
                                        current month (e.g., current month: Aug
                                        2024, 07/31/2024 and below is not valid)
                                    </li>
                                    <li>
                                        A first day of current/future month
                                        <span class="font-bold">
                                            mm/01/YYYY
                                        </span>
                                        (for Non-Refundable and RE Fee only)
                                    </li>
                                </ul>
                            </li>
                            <li>
                                End Dates must be:
                                <ul
                                    class="mt-2 list-inside list-square pl-[revert]"
                                >
                                    <!-- <li>
                                        In
                                        <span class="font-bold">
                                            mm/dd/YYYY
                                        </span>
                                        format (e.g. 08/16/2024) or empty which means onwards/to infinity
                                    </li> -->
                                    <li>Greater than Start Date</li>
                                    <li>
                                        A last day of current/future month
                                        <span class="font-bold">
                                            mm/{31/30/29/28}/YYYY
                                        </span>
                                        (for Non-Refundable and RE Fee only)
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Dates must not be overlapping with saved records
                            </li>
                        </ul>
                    </div>

                    <div class="mt-2"></div>
                    <div class="mt-2">
                        Thank you for your attention to this matter.
                    </div>
                </div>
            </div>

            <div
                class="flex items-center border-b border-gray-400/50
                    dark:border-darkmode-400"
            >
                <h2 class="mb-4 mr-auto text-base font-medium">Select File</h2>
            </div>

            <div
                v-if="
                    Object.keys($externalResults).length > 0
                "
                class="mt-4 rounded bg-red-200 px-4 text-red-600"
            >
                <h5 class="pt-4 font-semibold">Something went wrong.</h5>
                <ul class="list-disc p-4">
                    <li v-for="error in $externalResults as ErrorMessages">
                        {{ error[0] }}
                    </li>
                </ul>
            </div>

            <div class="mt-4">
                <p>
                    <input
                        ref="inputFile"
                        id="uploadFileBtn"
                        class="btn mr-2 shadow-md"
                        type="file"
                        @change="uploadFile"
                    />
                </p>
                <template v-if="validate.attachment.$error">
                    <div
                        v-for="(error, index) in validate
                            .attachment.$errors"
                        :key="index"
                        class="mt-2 text-danger"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </template>

        <template #action>
            <div class="flex flex-col sm:flex-row gap-2 justify-between">
                <div>
                    <Button
                        class="w-full sm:w-auto"
                        type="button"
                        variant="outline-secondary"
                        @click="download"
                    >
                        Download Template
                    </Button>
                </div>

                <div class="flex flex-col sm:flex-row gap-2 justify-end">
                    <Button
                        class="w-full sm:w-24"
                        type="button"
                        variant="outline-secondary"
                        @click="close"
                    >
                        Close
                    </Button>
                    <SubmitButton
                        id="btn_process"
                        class="w-full sm:w-32"
                        variant="primary"
                        processing-label="Importing..."
                        :is-processing="uploading"
                        @click="onClickUpload"
                    >
                        Upload
                    </SubmitButton>
                </div>
            </div>
        </template>
    </Modal>

    <Notification
        :options="{
            duration: 3000
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Employee invoice information has been imported.
            </div>
        </div>
    </Notification>

    <Notification
        v-if="
            backendValidationErrors &&
            Object.keys(backendValidationErrors).length
        "
        :options="{
            duration: 3000
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>

<style scoped></style>
