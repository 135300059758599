import { defineStore } from "pinia";
import Http from "../services/Http";
import { LaravelApiResourceInterface } from "../pages/interfaces/apiResponseInterface";
import { OfficeInterface } from "../pages/interfaces/officeInterface";

const baseUrl = import.meta.env.VITE_API_URL;

export const useOfficeStore = defineStore("OfficeStore", {
    state: (): {
        offices: LaravelApiResourceInterface<OfficeInterface>;
    } => {
        return {
            offices: {} as LaravelApiResourceInterface<OfficeInterface>,
        };
    },

    actions: {
        async getOffices(): Promise<void> {
            const data = await new Http(baseUrl).get<
                LaravelApiResourceInterface<OfficeInterface>
            >("/offices");

            this.offices = data;
        },
    },
});
